import React, { useState } from 'react'
import { AppContext } from './AppContext'


export const AppContextProvider = (props) => {
  const setIsAuthorizeUser = (val) => {
    setContext((prevState) => {
      const newState = { ...prevState }
      
      return Object.assign(newState, {
        IsAuthorizeUser : val
      })
    })
   };
  //  const authorizeUserEvent = (authorizeUser) => {
  //   setContext((prevState) => {
  //     const newState = { ...prevState }
      
  //     return Object.assign(newState, {
  //       authorizeUser : authorizeUser
  //     })
  //   })
  //  };
   const TriggerCameraEvent = (value) => {
    setContext((prevState) => {
      const newState = { ...prevState }
      
      return Object.assign(newState, {
        IsCameraOn : value
      })
    })
   };
   const ShowDrawPopupEvent = (value) => {
    setContext((prevState) => {
      const newState = { ...prevState }
      
      return Object.assign(newState, {
        ShowDrawPopup : value
      })
    })
   };
   const setCameraimgSource = (value) => {
    setContext((prevState) => {
      const newState = { ...prevState }
      
      return Object.assign(newState, {
        CameraimgSource : value
      })
    })
   };
   const setShowCameraFromForm = (value) => {
    setContext((prevState) => {
      const newState = { ...prevState }
      
      return Object.assign(newState, {
        ShowCameraFromForm : value
      })
    })
   };
   const setSignpadEvent = (padId,source) => {
    
    setContext((prevState) => {
     const newState = { ...prevState }
     
 //  let items = [...newState.signpadsPath];
 //  let myitem = items[index];
 //  //@ts-ignore
 //  myitem = source;
 //  items[index] = myitem;
  // 5. Set the state to our new copy
     return Object.assign(newState, {
       padId:padId,
       signpadsPath:source
       // signpadsPath: {
       //   ...newState.signpadsPath,
       //   [name] :
       //    source
 
       // }
       
     })
   })
  };
       
   const globalData = {
    CameraimgSource: "",
    ShowCameraFromForm:false,
    IsAuthorizeUser: false,
    // token: "",
    // authorizeUser: false,
    IsCameraOn:false,
    ShowDrawPopup:false,
    signpadsPath: "",
    padId: "",
    // setTokenEvent,
    // authorizeUserEvent,
    setIsAuthorizeUser,
    TriggerCameraEvent,
    setCameraimgSource,
    setShowCameraFromForm,
    ShowDrawPopupEvent,
    setSignpadEvent,

  }

   const [getContext, setContext] = useState(globalData);

  return (
    <AppContext.Provider value={getContext}>
      {props.children}
    </AppContext.Provider>
  )
}
