import React, { useState, useEffect, useContext } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { getAnamnesebögen } from "../../api";
import { NavLink, useSearchParams } from "react-router-dom";
import Header from "../../components/header";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { extendedsite } from "../../Config";

// import {AppContext} from '../Context/AppContext'
const SearchField = {
  backgroundColor: "#dee1dd",
  borderRadius: "10px",
  borderBottom: "none",
  color: "rgb(86,90,88)",
};
const Searchrow = {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #ccc",
};
const RecordRow = {
  display: "flex",
  alignItems: "center",
};
const RecordName = styled.div`
  font-size: 20px;
`;
const RecordSubtitle = styled.div`
  font-size: 12px;
  color: #4a4a4a;
`;
const BottomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #015270;
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
`;
const CheckCounter = styled.div`
  font-size: 20px;
  color: #fff;
`;
const FooterGreenButton = styled.a`
  background-color: #90ed8e;
  border: 1px solid #798772;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  margin-right: 25px;
  padding: 1rem;
  color: #000;
  text-decoration:none;
  &:hover {
    cursor: pointer;
  }
`;

const Nostyle = {
  color: "#000",
  textDecoration: "none",
};
function ShowList(props) {
  let navigate = useNavigate();

  let list = props.recordlist;
useEffect(() => {
  debugger
  var t=props.recordlist
}, [props.recordlist]);
  const [counter, setcounter] = useState(0);
  const [checkid, setcheckid] = useState([]);

  const checkboxChange = (e) => {
    e.target.checked === true
      ? setcounter(counter + 1)
      : setcounter(counter - 1);
    e.target.checked === true
      ? setcheckid([...checkid, e.target.id])
      : setcheckid(
        checkid.filter(function (value) {
          return value !== e.target.id;
        })
      );

  };
  useEffect(() => {
    localStorage.setItem("selectedTemplates", checkid);

  }, [checkid])

  //   const LinkClick = (e, fName, lName, id) => {
  //     // e.preventDefault();
  //     localStorage.setItem("firstName", fName);
  //     localStorage.setItem("surName", lName);
  //     localStorage.setItem("patientId", id);
  //   };
  const gotoCreatePatient = () => {
    navigate("/CreatePatient");
  };
  const setsiteinfo = () => {
    localStorage.removeItem("siteInfo");
  };
  return (
    <div style={{ paddingBottom: "70px" }}>
      {list !== null ? (
        list.map((value, index) => (
          <div
            className="p-4"
            style={{ borderBottom: "1px solid #ccc" }}
            key={index}
          // onClick={(e) => LinkClick(e)}
          >
            {/* <NavLink style={Nostyle} to={"/itemDetails/"+value.patientID} > */}
            <div style={RecordRow}>
              <div
                className=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      onChange={(e) => checkboxChange(e)}
                      checkedIcon={<CheckCircleIcon color="primary" />}
                      id={value.id}
                      name={`checkrow${index}`}
                      color="primary"
                    />
                  }
                />
                <div style={{ marginLeft: "10px" }}>
                  <RecordName>{value.title}</RecordName>
                  <div>
                    <RecordSubtitle>
                      Verfugbare Sprachen:{" "}
                      {value.languages.map((langvalue, langindex) =>
                        langindex !== value.languages.length ? langvalue : " |"
                      )}
                    </RecordSubtitle>
                  </div>
                </div>
              </div>
            </div>

            {/* </NavLink> */}
          </div>
        ))
      ) : (
        <p>No record</p>
      )}

      <BottomFooter>
        <CheckCounter>{counter} Vorlagen ausgewahlt</CheckCounter>
        {
          checkid.length > 0 ?
            <FooterGreenButton onClick={setsiteinfo} href={`${extendedsite}` + 'new/' + `${checkid}`+'|'+`${localStorage.getItem("practiceId")}`+
              "/ip/" +
              localStorage.getItem("ip")}>
              Ausfullen durch Patient starten
            </FooterGreenButton>
            :
            <FooterGreenButton onClick={setsiteinfo} href={`${extendedsite}` + 'new' +
              "/ip/" +
              localStorage.getItem("ip")}>
              Ausfullen durch Patient starten
            </FooterGreenButton>
        }
      </BottomFooter>
    </div>
  );
}

// href={
//   `${extendedsite}` +
//   "patient/" +
//   `${localStorage.getItem("patientId")}` +
//   "/" +
//   `${checkid}`+'|'+`${localStorage.getItem("practiceId")}`+
//   "/ip/" +
//   localStorage.getItem("ip")
// }

const Alert = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin-bottom: 10px;
`;

export default class PatientListCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defautlsearchvalue: "",
      recordList: [],
      defaultList: [],
      templateTypeId : window.location.href.split('/')[4]
    };

    this.handlechange = this.handlechange.bind(this);
  }

  
  componentDidMount() {
    // const {token} = this.context;

    getAnamnesebögen(this);
  }

  handlechange = (e) => {

    this.setState({
      recordList: this.state.defaultList.filter(
        (i) =>
          i.title.toLowerCase().includes(e.target.value.toLowerCase())),
      defautlsearchvalue: e.target.value.toLowerCase(),
    });
  };
  abortBtn = () => {
    this.setState({
      recordList: this.state.defaultList,
      defautlsearchvalue: "",
    });
  };

  render() {
    return (
      <>
        <Header
          backbtnTitle="Patienten auswahlen"
          leftButton="Back"
          title="Bogen fur Patienten auswahlen"
          rightButton=""
        />
        <Alert>
          <InfoOutlinedIcon />
          <span className="ml-2">
            Wahlen Sie die Bogen aus, die der Patient nach Eingabe Seiner
            Stammdaten ausfullen soll.
          </span>
        </Alert>
        <div style={Searchrow}>
          <div className="p-2" style={{ width: "100%" }}>
            <TextField
              type="text"
              id="searchBar"
              style={SearchField}
              fullWidth={true}
              onChange={(e) => this.handlechange(e)}
              value={this.state.defautlsearchvalue}
              placeholder="Vorlagen Suchen"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "rgb(86,90,88)", paddingLeft: "5px" }}
                    />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
          </div>
        </div>
        <ShowList recordlist={this.state.recordList} />
      </>
    );
  }
}
