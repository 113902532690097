import React from 'react'
import styled from 'styled-components'
import { colors } from '../utils/theme'
import { Container } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const ErrorContainer = styled(Container)`
  color: ${colors.red};
  text-align: center;
  padding: 20px 0;
`

export const FormError = () => (
  <ErrorContainer>
    <FormattedMessage id="error.submissionFailed" />
  </ErrorContainer>
)
