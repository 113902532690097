import React from 'react';
import ReactDOM from 'react-dom';
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppContextProvider} from "./Context/AppContextProvider"
import { LanguageProvider } from './Context/LanguageProvider'
import { PracticeProvider } from './Context/PracticeProvider'
import { DocumentFlowProvider } from './Context/DocumentFlowProvider'
import { InvitationDetailsProvider } from './Context/InvitationDetailsProvider'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/de'
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <LanguageProvider>
      <PracticeProvider>
        <DocumentFlowProvider>
          <InvitationDetailsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
              <AppContextProvider>
                <App />
              </AppContextProvider>
              </MuiPickersUtilsProvider>
          </InvitationDetailsProvider>
        </DocumentFlowProvider>
      </PracticeProvider>
    </LanguageProvider>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
