import React,{useState} from "react";
import styled from "styled-components"
import DrawOnImage from "./DrawOnCameraCaptureimg"
import Header from "../../components/header"

const ListRowwithImg = styled.a`
    padding:1rem;
    font-size:20px;
    font-weight:500;
    display:flex;
    border-bottom: 1px solid #ccc;
    align-items: center;

    &:hover {
        background-color: #f8f8f8;
        cursor:pointer
    }
    &:last-child {
        border-bottom: none
    }
    `
    const Listimg = styled.img`
    width:70px;
    height:70px;
    margin-right:15px;
    `
    const ListText = styled.p`
    font-size:24px;
    font-weight: 400;

    `

 const DsView = () => {

    const [imgpath , setimgpath] = useState("");
    const [EditedImg , setgetEditedImg] = useState("");
    const [open , setopen] = useState(false);
    const ListData = [
        {
        imgsrc:"https://www.darwinchambers.com/wp-content/uploads/demo-img.jpg",
        text: "Kamera",
        date : "13.November 2000"
    },
        {
        imgsrc:"https://thumbs.dreamstime.com/b/demo-computer-key-to-download-version-software-trial-64543995.jpg",
        text: "Rontgen",
        date : "13.November 2000"
    },
        {
        imgsrc:"https://st2.depositphotos.com/1186248/5903/i/600/depositphotos_59038425-stock-photo-demo.jpg",
        text: "Kamera",
        date : "11.November 1985"
    },
        {
        imgsrc:"https://t4.ftcdn.net/jpg/01/43/23/83/360_F_143238306_lh0ap42wgot36y44WybfQpvsJB5A1CHc.jpg",
        text: "Rontgen",
        date : "11.November 1985"
    },
];

const GetEditedImg = (img) => {
    setgetEditedImg(img)
}
const imgClick = (e) => {
    setimgpath(e.target.getAttribute("value"));
    setopen(true)
}

    return(
        <>
                                          <Header backbtnTitle="Medien" leftButton="Back" title="Bilder im Ds-View" rightButton="" />

        {ListData.map((i,index) => 
            <ListRowwithImg key={index} onClick={imgClick} value={i.imgsrc}>
            <Listimg src={i.imgsrc} />
            <ListText>
               {`${i.text} | ${i.date}`} 
            </ListText>
            </ListRowwithImg>

        )}
                      {open === true ? <DrawOnImage getimg={GetEditedImg} setBaseUrl={imgpath} triggerbycontext={false} showPopup={open} setshowpop={setopen} /> : null }

        </>
    )
}
export default DsView;