import React, { useState, useEffect, useContext } from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import styled from 'styled-components'
import { TransferWithinAStationSharp } from '@material-ui/icons';
import { getpatientlist } from "../api";
import { AppContext } from '../Context/AppContext'
import { NavLink } from "react-router-dom";
import Header from "../components/header"

// import {AppContext} from '../Context/AppContext'
const SearchField = {
    backgroundColor: "#dee1dd",
    borderRadius: "10px",
    borderBottom: "none",
    color: "rgb(86,90,88)"

}
const Searchrow = {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #ccc"
}
const RecordRow = {
    display: "flex",
    alignItems: "center"
}
const RecordName = styled.div`
font-size: 20px;
`
const RecordDate = styled.div`
font-size: 12px;
color:#4a4a4a;
`
const Idlink = styled.span`
background-color:#f5f5f5;
width:100%;
display:block;
text-decoration:none;
padding: 5px 0px 5px 8px;

color:#015270;
` ;
const Nostyle = {
    color: "#000",
    textDecoration: "none"
}
function ShowList(props) {
    let list = props.recordlist;

    const LinkClick = (e, fName, lName, id) => {
        // e.preventDefault();
        localStorage.setItem('firstName', fName);
        localStorage.setItem('surName', lName);
        localStorage.setItem('patientId', id);
        localStorage.setItem('siteInfo', "current");

    }


    return (
        <>


            {list !== null ? list.map((value, index) =>
                <div className="p-3" style={{ borderBottom: "1px solid #ccc" }} key={index} onClick={(e) => LinkClick(e, value.firstName, value.surName, value.patientID)}>
                    <NavLink style={Nostyle} to={"/itemDetails/" + value.patientID} >
                        <div style={RecordRow}>
                            <div style={{ width: "7%" }} className="pr-2">
                                <Idlink >
                                    {value.patientID}
                                </Idlink>
                            </div>
                            <div className="pl-2">
                                <RecordName>
                                    {value.firstName + ' ' + value.surName}
                                </RecordName>
                                <div>
                                    <RecordDate>
                                        {value.dateOFBirth}
                                    </RecordDate>
                                </div>
                            </div>
                        </div>

                    </NavLink>
                </div>

            ) : <p>No record</p>}
        </>
    )
}
export default class SearchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defautlsearchvalue: "",
            recordList: [],
            defaultList: [],
            ip: localStorage.getItem("ip") || null

        }

        this.handlechange = this.handlechange.bind(this);
    }

    componentDidMount() {
        // const {token} = this.context;
        if (!!this.state.ip && this.state.ip != 'null') {
            getpatientlist(this);
        } else {
            window.location.href = "/LocalServerError"
        }
    }

    handlechange = (e) => {
        this.setState({
            recordList: this.state.defaultList.filter(i => i.firstName.toLowerCase().includes(e.target.value.toLowerCase()) || i.surName.toLowerCase().includes(e.target.value.toLowerCase())),
            defautlsearchvalue: e.target.value.toLowerCase()
        })
    }
    abortBtn = () => {
        this.setState({
            recordList: this.state.defaultList,
            defautlsearchvalue: ""
        })
    }

    render() {

        return (
            <>
                <Header leftButton="Logout" title="Patienten auswahlen" rightButton="Create Patient" />
                <div style={Searchrow}>
                    <div className="p-2" style={{ width: "90%" }}>
                        <TextField
                            type="text"
                            id="searchBar"
                            style={SearchField}
                            fullWidth={true}
                            onChange={(e) => this.handlechange(e)}
                            value={this.state.defautlsearchvalue}
                            placeholder="Patienten Nach Name oder Geburtsdatum Suchen"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "rgb(86,90,88)", paddingLeft: "5px" }} />
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                            }}
                        />
                    </div>
                    <div className="p-2">
                        <Button color="primary" onClick={this.abortBtn}>Abbrechen</Button>
                    </div>
                </div>
                <ShowList recordlist={this.state.recordList} />
            </>
        )
    }
}

