import React,{useContext, useState} from 'react';
import Layout from "./layout";
import Login from "./login"
import Footer from "./footer"
import SearchList from "../Pages/SearchList"
import ItemDetails from "../Pages/ItemDetails"
import MediaList from "../Pages/Media/Media"
import { AppContext } from '../Context/AppContext'
import CameraPro from "../Pages/Media/CameraPro"
import CloseIcon from '@material-ui/icons/Close';
import DrawOnImage from "../Pages/Media/DrawOnCameraCaptureimg"
import { Routes, Route, Navigate } from "react-router-dom";
import DsView from "../Pages/Media/DSViewList"
import Illustration from "../Pages/Media/IllustrationsList"
import NewDocumentList from "../Pages/NewDocument/NewDocumentList"
import NewAnamneseDocumentList from "../Pages/NewAnamneseSheet/NewAnamneseSheetList"
import OwnInfoSheet from "../Pages/NewDocument/OwnInfoSheet"
import AthenaInfoSheet from "../Pages/NewDocument/AthenaInfoSheet"
import PublicLink from "../PublicLink"
import OwnanamneseSheet from "../Pages/NewAnamneseSheet/EigeneAnamnesebogen"
import AthenaanamneseSheet from "../Pages/NewAnamneseSheet/AthenaAnamnesebogen"
import {Summary} from "../Pages/Summary"
import PatientListCheckBox from "../Pages/Patient/PatientListCheckBox"
import CreatePatient from "../Pages/Patient/CreatePatient"
import Settings from  "../Pages/Settings"
import PdfViewer from "../components/pdfViewer"
import LocalServerError from "../Pages/LocalServerError";
import { SaveImage } from '../api';
import ImageViewer from './ImageViewer';
import NewDocumentType from '../Pages/NewDocument/NewDocumentType';

const Body = () => {
    const {IsCameraOn , TriggerCameraEvent , ShowDrawPopup , ShowCameraFromForm , setCameraimgSource , setShowCameraFromForm} = useContext(AppContext);
    const [getCaptureimg,setgetCaptureimg] = useState("");
    const [getEditimg,setEditimg] = useState("");

    const hidecam = () => { 
        TriggerCameraEvent(false)
    }
    const hidecamofForm = () => { 
        setShowCameraFromForm(false)
    }
    const getEditedImg = (img) => { 
        debugger
        setEditimg(img)
        SaveImage(img)
    }
   
    return(
        <Layout>
            {IsCameraOn === true ? <CameraPro isFromform={false} sendBase64={setgetCaptureimg} /> : null}
            {ShowCameraFromForm === true ? <CameraPro isFromform={true}  /> : null}
            {ShowCameraFromForm &&  <div style={{position:"absolute",top:"5px",right: "10px",zIndex:99,color:"#fff"}}><CloseIcon  style={{cursor:"pointer"}}  onClick={hidecamofForm} /></div>  }
            {IsCameraOn &&  <div style={{position:"absolute",top:"5px",right: "10px",zIndex:99,color:"#fff"}}><CloseIcon  style={{cursor:"pointer"}}  onClick={hidecam} /></div>  }
            {ShowDrawPopup === true ? <DrawOnImage getimg={getEditedImg} setBaseUrl={getCaptureimg} triggerbycontext={true} showPopup={ShowDrawPopup} /> : null }
            <Routes>
                <Route path="/patientlist" element={<SearchList />} />
                <Route path="/" element={<Navigate replace to="/patientlist" />} />
                <Route path="/LocalServerError" element={<LocalServerError/>}/>
                <Route path="/mediaList" element={<MediaList />} />
                <Route path="/itemDetails/:patientId" element={<ItemDetails />} />
                <Route path="/dsView" element={<DsView />} />
                <Route path="/illustration" element={<Illustration />} />
                <Route path="/newDocumentList" element={<NewDocumentList />} />
                <Route path="/newDocumentType" element={<NewDocumentType />} />
                <Route path="/login" element={<Login />} />
                <Route path="/infosheet" element={<OwnInfoSheet />} />
                <Route path="/athenasheet" element={<AthenaInfoSheet />} />
                <Route path="/newAnamnesesheet" element={<NewAnamneseDocumentList />} />
                <Route path="/athenaAnamnesebogenlist" element={<AthenaanamneseSheet />} />
                <Route path="/eigeneAnamnesebogenlist" element={<OwnanamneseSheet />} />
                <Route path=":token" element={<PublicLink />} />
                <Route path="/summary" element={<Summary />} />
                <Route path="/SelectPatient/:templateTypeId" element={<PatientListCheckBox />} />
                <Route path="/CreatePatient" element={<CreatePatient />} />
                <Route path="/Settings" element={<Settings />} />
                <Route path="/pdf/:id" element={<PdfViewer />} />
                <Route path="/image/:id" element={<ImageViewer />} />
            </Routes>
            {/* <Footer /> */}
        </Layout>
    )
}

export default Body;