import messages_de from './de.json'
import messages_en from './en.json'
import messages_ar from './ar.json'
import messages_tr from './tr.json'
import messages_fr from './fr.json'
import messages_es from './es.json'
import messages_ru from './ru.json'

export interface Language {
  key: string
  label: string
  messages: Record<string, string>
}

export const languages = [
  { key: 'de', label: 'Deutsch', messages: messages_de },
  { key: 'en', label: 'English', messages: messages_en },
  { key: 'ar', label: 'العربية', messages: messages_ar },
  { key: 'tr', label: 'Türkçe', messages: messages_tr },
  { key: 'fr', label: 'Français', messages: messages_fr },
  { key: 'es', label: 'Español', messages: messages_es },
  { key: 'ru', label: 'русский', messages: messages_ru },
  { key: 'pl', label: 'polski', messages: messages_de },
  { key: 'ch', label: '中文', messages: messages_de },
  { key: 'ja', label: '日本人', messages: messages_de },
] as Language[]

export const languageKeys = languages.map((l) => l.key)

export const getLanguage = (key: string) =>
  languages.filter((l) => l.key === key)[0]
