import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import Body from "./components/body";
import Login from "./components/login";
import { AppContext } from "./Context/AppContext";
import { useParams, useNavigate } from "react-router-dom";

function App() {
  let navigate = useNavigate();

  const { IsAuthorizeUser } = useContext(AppContext);
  // if(JSON.parse(sessionStorage.getItem("token")) !== null){
  //   navigate("/");
  // }
  
  return (
    <>
      <div className="App">
        {JSON.parse(localStorage.getItem("token")) !== null ? (
          <Body />
        ) : (
          <Login />
        )}
      </div>
     
    </>
  )
}

export default App;
