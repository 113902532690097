import React, {useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Header from "../components/header"
import styled from "styled-components"
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { GetAppOptions,SaveAppOptions } from "../api";

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


const MainContainer = styled.div`
padding-left: 2.5rem!important;
padding-right: 2.5rem!important;
padding-bottom:4.5rem;
`
const FirstHeading = styled.p`
padding:15px 0;
font-size:17px;
margin-bottom:0;
`
const BoldVersion = styled.span`
font-weight:500
`
const BorderBottomDiv = styled.div`
border-bottom:1px solid #eee;
&:last-child{
    border-bottom:0;
}

`
const SwitchRow = styled.div`
display:flex;
justify-content:space-between;
align-items:center
`
const Captions = styled.p`
margin:0;
padding:15px 0;
font-size:17px;
`
const LightText = styled.span`
color:#ccc;

`
const BlueText = styled.span`
color:blue;
`
const BottomFooter = styled.div`
position: fixed;
    bottom: 0;
    width: 99%;
    padding: 5px;
    border-top: 1px solid #ccc;
    background-color: #fff;
    display: block;
    z-index:9;
    text-align:right
`
const SaveBtn = styled.button`
padding: 15px;
border:1px solid #015270;
color: #015270;
background-color: transparent;
height:100%;
cursor:pointer;
border-radius:5px;
margin-right:0px;
font-size: 18px;

&:hover{
  background-color: #015270;
  color:white
}
`

function Settings() {
    const [state, setState] = React.useState({
        Id:0,
        AllowPreviousEntries: false,
        sendBugReports: false,
        analysisData: false,
        letter: false,
        SMS: false,
        email: false,
        phone: false,
        reirectloaction:"Detailpatients",
        practiceId: localStorage.getItem("practiceId")
    });
    const [value, setValue] = React.useState('Detailpatients');
    useEffect(() => {
        async function fetchData() {
            let response= await GetAppOptions();
            setState({ 
                ...state, 
                Id: response.id,
                AllowPreviousEntries: response.allowPriviousEntry,
                sendBugReports: response.bugReports,
                analysisData: response.sendanalyticsdata,
                reirectloaction: response.navigateTo,
                // letter: response.sendanalyticsdata,
                // email: response.sendanalyticsdata,
                // phone: response.sendanalyticsdata,
                // SMS: response.sendanalyticsdata,
            });
          }
          fetchData()
    }, [])
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleradioChange = (e) => {
        setState({ ...state, reirectloaction: e.target.value });
    }
    const onSubmit = (e) => {
        SaveAppOptions(state);
    }
    return (
        <>
            <Header backbtnTitle="Zuruck" leftButton="Back" title="Einstellungen" rightButton="" />
            <MainContainer>
                <FirstHeading>
                    Sie nutzen die Version <BoldVersion>2021.3.0 (Build 173)</BoldVersion> der Athena-App.
                </FirstHeading>
                <BorderBottomDiv className="">
                    <Typography color="primary" variant="h5" gutterBottom>
                        Anamnese
                    </Typography>
                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <SwitchRow>
                        <Captions>
                            Laden von fruheren Eingaben Erlauben <LightText>(Praxisweite Einstellung)</LightText>
                        </Captions>
                        <FormControlLabel
                            control={<IOSSwitch checked={state.AllowPreviousEntries} onChange={handleChange} name="AllowPreviousEntries" />}
                            label=""
                            className="mr-0"
                        />
                    </SwitchRow>

                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <Captions>
                        Wenn Doese Einstellung aktiviert ist, konnen Patienten Beim Erneuten Ausfullen desselben AnamneseBogens  Ihre Fruheren Angaben in allen Feldern Vorausfullen Lassen.
                    </Captions>
                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <Captions className="pb-0">
                        Nach dem Speichern eines Anamnesebogens navigieren zuruck zur <LightText>(Praxisweite Einstellung)</LightText>
                    </Captions>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="reirectloaction" row value={state.reirectloaction} onChange={handleradioChange}>
                            <FormControlLabel value="allpatients"  control={<Radio color="primary" />} label="Liste mit allen Patienten" />
                            <FormControlLabel value="Detailpatients" control={<Radio color="primary"  />} label="Detailansicht des Patienten" />
                        </RadioGroup>
                    </FormControl>
                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <Captions >
                        Diese Einstellung Bestimmt, welche Seite nach dem Entsperren der App angezeigt wird.
                    </Captions>
                    <Typography color="primary" variant="h5" gutterBottom>
                        Fehlerberichte und Analysedaten
                    </Typography>
                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <SwitchRow>
                        <Captions>
                            Zustimmung zum Senden von Fehlerberichten <LightText>(Praxisweite Einstellung)</LightText>
                        </Captions>
                        <FormControlLabel
                            control={<IOSSwitch checked={state.sendBugReports} onChange={handleChange} name="sendBugReports" />}
                            label=""
                            className="mr-0"
                        />
                    </SwitchRow>

                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <Captions>
                        Fehlerberichte enthalten technische Informationen, mit denen wir etwaige Absturze nachvollziehen konnen. Fur das Erheben und
                        Ubermitteln der Fehlerberichte nutzen wir den Dienst "App Center" von Microsoft. Im Rahmen der Fehlerberichte werden
                        Informationen uber lhr iPad (z.B. eindeutige Geratekennung, Modellversion, Version des Betriebssystems) sowie lhre Athena-
                        Benutzerkennung ubermittelt.Zusatzlich wird lhre Ip-Adresse von Microsoft temporar gespeichert, um einen ordnungsgemaBen
                        Betrieb Des Dienstes zu gewahrleisten.
                    </Captions>
                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <SwitchRow>
                        <Captions>
                            Zustimmung zum Ubermitteln von Analysedaten <LightText>(Praxisweite Einstellung)</LightText>
                        </Captions>
                        <FormControlLabel
                            control={<IOSSwitch checked={state.analysisData} onChange={handleChange} name="analysisData" />}
                            label=""
                            className="mr-0"
                        />
                    </SwitchRow>

                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <Captions>
                        Die Analsedaten umfassen Informationen uber lhr iPad (z.B. Modellversion und Version des Betriebssystems) und zum
                        Nutzungsverhalten. Letzteres umfasst die Haufigkeit der Nutzung sowie bestimmte Ereignisse. z.B. "Beratungsprotokoll erstellt".
                        Die erfassten Daten weden pseudonymisiert erfasst und konnen von uns nicht Ihrem Benutzerkonto zugeordnet werden. Auch
                        hier wird Ihre IP-Adresse von Microsoft temporar gespeichert, um einen ordnungsgemaBen Betrieb des Dienstes zum
                        gewahrleisten.
                        <br />
                        Weitere Informationen zum Datenschutz finden Sie in unserer <BlueText> Datenschutzerklarung.</BlueText>
                    </Captions>
                    <Typography color="primary" variant="h5" gutterBottom>
                        Recall-Optionen
                    </Typography>
                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <SwitchRow>
                        <Captions>
                            Erinnerung uber <LightText>(Praxisweite Einstellung)</LightText>
                        </Captions>
                        <FormControl required component="fieldset" >
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox color="primary" icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleOutlinedIcon color="primary" />} color="primary" name="letter" checked={state.letter} onChange={handleChange} />}
                                    label="Brief"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleOutlinedIcon color="primary" />} color="primary" name="SMS" checked={state.SMS} onChange={handleChange} />}
                                    label="SMS"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleOutlinedIcon color="primary" />} color="primary" name="email" checked={state.email} onChange={handleChange} />}
                                    label="E-Mail"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleOutlinedIcon color="primary" />} color="primary" name="phone" checked={state.phone} onChange={handleChange} />}
                                    label="Telefon"
                                />

                            </FormGroup>
                        </FormControl>
                    </SwitchRow>

                </BorderBottomDiv>
                <BorderBottomDiv className="">
                    <Captions>
                        Die Optionen, die Sie hier auswahlen, werden in den Anamnesebogen in der App und in Anamnese@Home-Links im Recall-Element angezeigt.
                    </Captions>

                </BorderBottomDiv>

            </MainContainer>
            <BottomFooter>
                <SaveBtn type="submit" onClick={onSubmit}>
                    Speichern
                </SaveBtn>
            </BottomFooter>
        </>
    );
}

export default Settings;