import React, { useEffect , useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GestureIcon from '@material-ui/icons/Gesture';
import Drawonjaw from "./DrawonJaw";
import { AppContext } from '../../Context/AppContext'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DrawOnImage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.showPopup);
  const {ShowDrawPopupEvent} = useContext(AppContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
    if(props.triggerbycontext === true){

      ShowDrawPopupEvent(false)
    } 
    if(props.setshowpop !== undefined){
      props.setshowpop(false);
    }
  };
  // useEffect(() => {
  
  //   setOpen(props.showPopup)
  
  // }, [open]);


  return (
    <div>
          {/* <IconButton color="default" aria-label="upload picture" onClick={handleClickOpen} component="span">
       <GestureIcon />
            </IconButton> */}
    
      <Dialog fullScreen open={props.showPopup} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Draw Onto Image
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
       <Drawonjaw getimg={props.getimg} setimg={props.setBaseUrl} closepopup={handleClose} />
      </Dialog>
    </div>
  );
}
