import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled from "styled-components";
import Loader from "react-loader-spinner";


const LoaderContainer = styled.div`
position:fixed;
top:0;
left:0;
bottom: 0;
right:0;
background: rgba(255,255,255,0.5);
display:flex;
z-index:999;
justify-content: center;
align-items: center;
`

function AddLoader(props) {
    return (
        <LoaderContainer>
            <Loader
            {...props}
            
        />
      </LoaderContainer>
     );
}

export default AddLoader;