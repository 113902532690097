import Header from "../components/header"
export default function LocalServerError() {

  return (
    <>
      <Header leftButton="Logout" title="Patienten auswahlen" rightButton="Create Patient" />
      <div className="p-2">
        Local Server Connection Issue.
      </div>
    </>
  )
}