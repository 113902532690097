import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisFlow } from './types/AnamnesisFlow'
import { PublicLinkParams } from './types/Params'
import { DocumentFlow } from './Pages/DocumentFlow/index'
import { DocumentFlowContext } from './Context/DocumentFlowContext'
import { PracticeContext } from './Context/PracticeContext'
import { templateServer } from './Config'
import { ThankYou } from './Pages/ThankYou'
import { useParams } from 'react-router-dom'
import { Welcome } from './Pages/Welcome'
import { Fallback } from './Pages/Fallback'
import { Loading } from './Pages/Loading'
import { getLanguagesSubset } from './utils/languageHelpers'
import { LanguageContext } from './Context/LanguageContext'
import { ATNType } from './types/ATNTypes'
import { DocumentTemplate } from "./Pages/DocumentTemplate"
const PublicLink = () => {
  //@ts-ignore
  const { token } = useParams<PublicLinkParams>()
  const { setPractice, loaded } = useContext(PracticeContext)
  const { stage, setTemplates, setIsLoading , setStage  } = useContext(DocumentFlowContext)
  const { setSupportedLanguages } = useContext(LanguageContext)
  const tokenstore =JSON.parse(localStorage.getItem('token')||"");
  const [{ loading, error }, requestData] = useAxios<AnamnesisFlow>(
    {
      url: `${templateServer}/${token}`,
      headers: {
        'Authorization': 'Bearer '+tokenstore 
      }

    },
    {
      manual: true,
    },
  )

  useEffect(() => {
    setIsLoading(true)
    requestData()
      .then((result) => {
        setPractice(result.data.practice)

        for (let i = 0; i < result.data.document_templates.length; i++) {
          result.data.document_templates[i].atn = JSON.parse(result.data.document_templates[i].atn.toString());
          result.data.document_templates[i].languages = JSON.parse(result.data.document_templates[i].languages.toString());
          
        }
        setTemplates(result.data.document_templates)
        setSupportedLanguages(
          getLanguagesSubset(result.data.document_templates),
        )
        setStage('documentFlow')
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }, [
    requestData,
    setPractice,
    setTemplates,
    setIsLoading,
    setSupportedLanguages,
  ])

  // TODO optimise re-rendering
  // console.log('rendering')

  if (loading){
    return <Loading />
  }
  if (error) return <Fallback error />

  if (loaded) {
    switch (stage) {
      // case 'welcome':
      //   return <Welcome />
      case 'documentFlow':
        return <DocumentFlow extraSubmissionData={{ token }} />
      // case 'thankYou':
      //   return <ThankYou />
    }
  } else {
    return <Loading />
  }
  return null;
}

export default PublicLink
