import styled from 'styled-components'
import { colors } from '../utils/theme'

const StyledLabel = styled('h3')`
  font-size: 18px;
  color: ${colors.grey};
  margin-top: 5px;
  margin-bottom: 10px;
`
export default StyledLabel
