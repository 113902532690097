import { languageKeys } from '../translations/languageConfig'
import { DocumentTemplateInterface } from '../types/DocumentFlow'

export const getMultilingualATNLabel = (text: string) =>
  languageKeys.reduce((o, key) => ({ ...o, [key]: text }), {})

export const getLanguagesSubset = (templates: DocumentTemplateInterface[]) => {
  const languages = templates.map((t) => t.languages)
  return languages.reduce((supportedLanguages, array) =>
    supportedLanguages.filter((value) => array.includes(value)),
  )
}
