import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import styled from "styled-components"
import TextField from '@material-ui/core/TextField';
import { submissionServer,ImageServer } from '../Config'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const axios = require('axios');

const CenterPageContent = styled.div`
height:100%;
display:flex;
justify-content:center;
align-items:center
` 
const BigText = styled.h1`
font-weight:400;
font-size:30px;
text-align:center;
color :#015270 !important
`

const GreenBtn = styled.button`
Background-color:#90ed8e;
border : 1px solid #798772;
border-radius: 10px;
text-align:center;
font-size: 20px;
height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    margin-left: 25px;

`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuperPassword(props) {
  const [open, setOpen] = React.useState(false);
  const [pass, setpass] = React.useState("")
  let navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPasschnage = (e) => {
    setpass(e.target.value)
  }
  
  const submitPassword = () => {
    const token  = JSON.parse(localStorage.getItem('token') || "");
    axios({
        method: 'Post',
        url: ImageServer+'api/checkPassword',
        headers: {
          'Authorization': 'Bearer '+token,
          'passwordKey':pass
        }

      }).then((result) => {
        
        if(result.data)
        {
          navigate("/itemDetails/"+localStorage.getItem('patientId'));
        }else{
          Swal.fire({
            title: 'Error!',
            text: 'Password not correct...',
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }
      
        // setStage('thankYou')
      });
  };

  return (
    <div>
     
      <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
      
        <CenterPageContent>
            <div>
            <BigText>
                Sie haben das Ausfullen des Dokuments abgebrochen. <br />
                Bitte bringen Sie das Tablet zuruck zum Empfang.
            </BigText>
            <div className="" style={{display:'flex',alignItems:"center",justifyContent:'center'}}>
            <TextField id="outlined-basic" label="Sperrpasswort Eingeben" variant="outlined" onChange={onPasschnage} />
            <GreenBtn onClick={submitPassword}>
                Entsperren
            </GreenBtn>
            </div>
            </div>
        </CenterPageContent>
      </Dialog>
    </div>
  );
}