import React, { Component } from "react";
import Header from "../../components/header";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { DatePicker } from "@material-ui/pickers";
import ReactFlagsSelect from "react-flags-select";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createPatient } from "../../api";
import { Navigate } from "react-router-dom";

const FormWrapper = styled.div`
  padding: 30px;
`;
const SubmitBtn = styled.button`
  background: transparent;
  border: 1px solid #015270;
  padding: 1rem;
  font-weight: 500;
  color: #015270;
  border-radius: 5px;
  cursor: pointer;
  width: 25%;
  &:hover {
    background-color: #015270;
    color: #fff;
    transition: 0.2s;
  }
`;

class CreatePatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        salutation: "",
        title: "",
        firstName: "",
        lastName: "",
        dob: new Date(),
        gender: "",
        address: "",
        address2: "",
        postalcode: "",
        city: "",
        country: "DE",
        phone: "",
        phone2: "",
        mobilenum: "",
        fax: "",
        email: "",
        insuredPerson: "",
       


        salutationNew: "",
        titleNew: "",
        firstNameNew: "",
        lastNameNew: "",
        dobNew: new Date(),
        addressNew: "",
        address2New: "",
        postalcodeNew: "",
        cityNew: "",
        countryNew: "DE",
        phoneNew: "",
        
      },
      redirect:false,
      redirectid : "",
      newForm:false

    };
  }

  handleDateChange = (e) => {
    this.setState({
      selectedDate: e,
    });
  };
  componentWillUnmount = () => {
    this.setState({
      redirect: false,
       redirectid:""
    });
  }
  render() {
    
    if(this.state.redirect !== false && this.state.redirectid !== ""){
     return <Navigate to={`/${this.state.redirectid}`} replace={true} state={{fromClient:this.state.redirect}} />
    }
    return (
      <>
        <Header
          backbtnTitle="Zuruck"
          leftButton="Back"
          title="Personliche Angaben"
          rightButton=""
        />
        <FormWrapper>
          <Paper elevation={1} className="p-4">
            <Formik
              validateOnChange={false}
              initialValues={this.state.initialValues}
              validationSchema={Yup.object({
                firstName: Yup.string().required("Required"),
                salutation: Yup.string().required("Required"),
                title: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                dob: Yup.string().required("Required"),
                gender: Yup.string().required("Required"),
                address: Yup.string().required("Required"),
                postalcode: Yup.string().required("Required"),
                city: Yup.string().required("Required"),
                country: Yup.string().required("Required"),
                phone: Yup.string().required("Required"),
                mobilenum: Yup.string().required("Required"),
                fax: Yup.string().required("Required"),
                email: Yup.string().email().required("Email is required"),
                insuredPerson: Yup.string().required("Required"),
              })}
              onSubmit={async (values) => {

                  let checkDelete = await createPatient(values);
                  if(localStorage.getItem("selectedTemplates").split(",").length>0)
                  {
                    
                    let id = localStorage.getItem("selectedTemplates").split(",")[0];

                    localStorage.setItem("selectedTemplates" , localStorage.getItem("selectedTemplates").split(",").filter(i => i !== id));

                    this.setState({
                      redirect: true,
                      redirectid:id
                    })
                  }
              
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h5" color="primary" gutterBottom>
                    {" "}
                    Personliche Angaben
                  </Typography>
                  {/* <Typography variant="caption" display="block" gutterBottom>
                                        Anrede
                                  </Typography> */}
                  <FormControl
                    component="fieldset"
                    className="mb-3"
                    error={
                      formik.touched.salutation &&
                      Boolean(formik.errors.salutation)
                    }
                  >
                    <FormLabel component="legend">Anrede</FormLabel>
                    <RadioGroup
                      row
                      aria-label="salutation"
                      name="salutation"
                      {...formik.getFieldProps("salutation")}
                    >
                      <FormControlLabel
                        value="Mr"
                        control={<Radio color="primary" />}
                        label="Herr"
                      />
                      <FormControlLabel
                        value="Mrs"
                        control={<Radio color="primary" />}
                        label="Frau"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {formik.touched.salutation && formik.errors.salutation}
                    </FormHelperText>
                  </FormControl>

                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Titel"
                        type="text"
                        {...formik.getFieldProps("title")}
                        label="Titel"
                        variant="outlined"
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={formik.touched.title && formik.errors.title}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Vorname"
                        type="text"
                        {...formik.getFieldProps("firstName")}
                        label="Vorname"
                        variant="outlined"
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Nachname"
                        type="text"
                        {...formik.getFieldProps("lastName")}
                        label="Nachname"
                        variant="outlined"
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DatePicker
                        // disableFuture
                        // openTo="year"
                        format="l"
                        label="Geburtsdatum"
                        views={["year", "month", "date"]}
                        value={formik.values.dob}
                        onChange={(value) => formik.setFieldValue("dob", value)}
                        inputVariant="outlined"
                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                        helperText={formik.touched.dob && formik.errors.dob}
                        // {...formik.getFieldProps('dob')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        component="fieldset"
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                      >
                        <FormLabel component="legend">Geschlecht</FormLabel>
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="gender"
                          {...formik.getFieldProps("gender")}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="mannlich"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label="weiblich"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label="divers"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {formik.touched.gender && formik.errors.gender}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="pb-0">
                      <Typography variant="h5" color="primary" gutterBottom>
                        {" "}
                        Anschrift
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Strabeundhausnummer"
                        type="text"
                        {...formik.getFieldProps("address")}
                        label="Strabe und hausnummer"
                        variant="outlined"
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Starbenzusatz"
                        type="text"
                        {...formik.getFieldProps("address2")}
                        label="Starbenzusatz"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Postleitzahl"
                        type="text"
                        {...formik.getFieldProps("postalcode")}
                        label="Postleitzahl"
                        variant="outlined"
                        error={
                          formik.touched.postalcode &&
                          Boolean(formik.errors.postalcode)
                        }
                        helperText={
                          formik.touched.postalcode && formik.errors.postalcode
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Stadt"
                        type="text"
                        {...formik.getFieldProps("city")}
                        label="Stadt"
                        variant="outlined"
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ReactFlagsSelect
                        selected={formik.values.country}
                        onSelect={(code) =>
                          formik.setFieldValue("country", code)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className="pb-0">
                      <Typography variant="h5" color="primary" gutterBottom>
                        {" "}
                        Kontaktmoglichkeiten
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Telefon"
                        type="text"
                        {...formik.getFieldProps("phone")}
                        label="Telefon"
                        variant="outlined"
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Telefon2"
                        type="text"
                        {...formik.getFieldProps("phone2")}
                        label="Telefon 2"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Mobil"
                        type="text"
                        {...formik.getFieldProps("mobilenum")}
                        label="Mobil"
                        variant="outlined"
                        error={
                          formik.touched.mobilenum &&
                          Boolean(formik.errors.mobilenum)
                        }
                        helperText={
                          formik.touched.mobilenum && formik.errors.mobilenum
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="Fax"
                        type="text"
                        {...formik.getFieldProps("fax")}
                        label="Fax"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        id="email"
                        type="email"
                        {...formik.getFieldProps("email")}
                        label="E-mail"
                        variant="outlined"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        error={
                          formik.touched.insuredPerson &&
                          Boolean(formik.errors.insuredPerson)
                        }
                      >
                        <FormLabel component="legend">
                          Stimmen die Daten des Patienten mit denen des
                          Hauptversicherten uberein?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="insuredPerson"
                          name="insuredPerson"
                          onChange={(value) => {
                            formik.setFieldValue("insuredPerson", value.target.value); this.setState({newForm:value.target.value === "no" ? true:false})
                          
                          }}
                          // {...formik.getFieldProps("insuredPerson")}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label="Ja"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label="Nein"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {formik.touched.insuredPerson &&
                            formik.errors.insuredPerson}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {
                      this.state.newForm === true ? 
                      <>
                      <div className="p-3">
                      <Typography variant="h5"  style={{display:"block",width:"100%"}} color="primary" gutterBottom>
                      {" "}
                      Personliche Angaben Versicherter
                    </Typography>
                    {/* <Typography variant="caption" display="block" gutterBottom>
                                          Anrede
                                    </Typography> */}
                    <FormControl
                      component="fieldset"
                      className="mb-3"
                      error={
                        formik.touched.salutationNew &&
                        Boolean(formik.errors.salutationNew)
                      }
                    >
                      <FormLabel component="legend">Anrede</FormLabel>
                      <RadioGroup
                        row
                        aria-label="salutation"
                        name="salutationNew"
                        {...formik.getFieldProps("salutationNew")}
                      >
                        <FormControlLabel
                          value="Mr"
                          control={<Radio color="primary" />}
                          label="Herr"
                        />
                        <FormControlLabel
                          value="Mrs"
                          control={<Radio color="primary" />}
                          label="Frau"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {formik.touched.salutationNew && formik.errors.salutationNew}
                      </FormHelperText>
                    </FormControl>
  
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Titel"
                          type="text"
                          {...formik.getFieldProps("titleNew")}
                          label="Titel"
                          variant="outlined"
                          error={
                            formik.touched.titleNew && Boolean(formik.errors.titleNew)
                          }
                          helperText={formik.touched.titleNew && formik.errors.titleNew}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Vorname"
                          type="text"
                          {...formik.getFieldProps("firstNameNew")}
                          label="Vorname"
                          variant="outlined"
                          error={
                            formik.touched.firstNameNew &&
                            Boolean(formik.errors.firstNameNew)
                          }
                          helperText={
                            formik.touched.firstNameNew && formik.errors.firstNameNew
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Nachname"
                          type="text"
                          {...formik.getFieldProps("lastNameNew")}
                          label="Nachname"
                          variant="outlined"
                          error={
                            formik.touched.lastNameNew &&
                            Boolean(formik.errors.lastNameNew)
                          }
                          helperText={
                            formik.touched.lastNameNew && formik.errors.lastNameNew
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <DatePicker
                          // disableFuture
                          // openTo="year"
                          format="l"
                          label="Geburtsdatum"
                          views={["year", "month", "date"]}
                          value={formik.values.dobNew}
                          onChange={(value) => formik.setFieldValue("dobNew", value)}
                          inputVariant="outlined"
                          error={formik.touched.dobNew && Boolean(formik.errors.dobNew)}
                          helperText={formik.touched.dobNew && formik.errors.dobNew}
                          // {...formik.getFieldProps('dob')}
                        />
                      </Grid>
                     
                      <Grid item xs={12} className="pb-0">
                        <Typography variant="h5" color="primary" gutterBottom>
                          {" "}
                          Anschrift Versicherter
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Strabeundhausnummer"
                          type="text"
                          {...formik.getFieldProps("addressNew")}
                          label="Strabe und hausnummer"
                          variant="outlined"
                          error={
                            formik.touched.addressNew &&
                            Boolean(formik.errors.addressNew)
                          }
                          helperText={
                            formik.touched.addressNew && formik.errors.addressNew
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Starbenzusatz"
                          type="text"
                          {...formik.getFieldProps("address2New")}
                          label="Starbenzusatz"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Postleitzahl"
                          type="text"
                          {...formik.getFieldProps("postalcodeNew")}
                          label="Postleitzahl"
                          variant="outlined"
                          error={
                            formik.touched.postalcodeNew &&
                            Boolean(formik.errors.postalcodeNew)
                          }
                          helperText={
                            formik.touched.postalcodeNew && formik.errors.postalcodeNew
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Stadt"
                          type="text"
                          {...formik.getFieldProps("cityNew")}
                          label="Stadt"
                          variant="outlined"
                          error={
                            formik.touched.cityNew && Boolean(formik.errors.cityNew)
                          }
                          helperText={formik.touched.cityNew && formik.errors.cityNew}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ReactFlagsSelect
                          selected={formik.values.countryNew}
                          onSelect={(code) =>
                            formik.setFieldValue("countryNew", code)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} className="pb-0">
                        <Typography variant="h5" color="primary" gutterBottom>
                          {" "}
                          Kontaktmoglichkeiten Versicherter
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          fullWidth
                          id="Telefon"
                          type="text"
                          {...formik.getFieldProps("phoneNew")}
                          label="Telefon"
                          variant="outlined"
                          error={
                            formik.touched.phoneNew && Boolean(formik.errors.phoneNew)
                          }
                          helperText={formik.touched.phoneNew && formik.errors.phoneNew}
                        />
                      </Grid>
                     
                      </Grid>
                      </div>
      </>
                      
                      
                      
                      :null
                    }
                    <Grid item xs={12} className="text-right">
                      <SubmitBtn type="submit">Speichern</SubmitBtn>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </FormWrapper>
      </>
    );
  }
}

export default CreatePatient;


