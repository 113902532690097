import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import PrintIcon from "@material-ui/icons/Print";
import { NavLink } from "react-router-dom";
import Header from "../components/header";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { GetdocumentslistForpatient, GetdocumentSummery, deletedraftRecord, GetpatientDetail } from "../api";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import print from "print-js";
import { format } from "date-fns";
import AddLoader from "../components/Loader";
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const DateLabel = styled.div`
  background-color: #bce6ff;
  color: #172841;
  padding: 3px 5px;
  border-radius: 5px;
  margin-left: 15px;
  text-transform: lowercase;
`;

function TabsNav(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const tabLabelstyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{
          borderTop: "1px solid #ccc",
          backgroundColor: "#edede7",
          boxShadow: "none",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="DOKUMENTE" {...a11yProps(0)} />
          <Tab
            label={
              <div style={tabLabelstyle}>
                ANAMNESE_RADAR
                {props.data.length > 0 ?
                  <DateLabel>

                    {format(
                      new Date(
                        props.data[props.data.length - 1].submitted_at
                      ),
                      "MMM dd yyyy p "
                    )
                    }
                  </DateLabel>
                  : ""}
              </div>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>

      <TabPanel
        value={value}
        index={0}
        className="p-0 removepadding"
        dir={theme.direction}
      >
        <TabsNavItem delDraft={props.delDraft} data={props.data} loading={props.loading} />
      </TabPanel>
      <TabPanel
        value={value}
        className="p-0 removepadding"
        index={1}
        dir={theme.direction}
      >
        <TabsNavItemSecond data={props.data} />
      </TabPanel>
    </div>
  );
}

const ItemListRow = styled.div`
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 15px 20px;
`;

const SmallDateAndTime = styled.div`
  font-size: small;
  color: #ccc;
  line-height: 10px;
`;
const BigText = styled.div`
  font-size: 18px;
`;

function TabsNavItem(props) {
  const [urlpath, seturlpath] = useState("");

  const getSummery = async (e) => {
    props.loading(true);
    var filepath = await GetdocumentSummery(e.currentTarget.id, props);
    props.loading(false);
    if (filepath === "Empty") {
      Swal.fire({
        title: 'Empty Template...',
        icon: 'info',
        confirmButtonText: 'Close'
      })
    }
    else {
      seturlpath(filepath);
    }


  };
  const deletedraft = (e) => {


    props.delDraft(e.currentTarget.id)
  };
  useEffect(() => {
    if (!!urlpath) {
      //var url = urlpath //.replace(/\\/g, "/");
      var url = urlpath[0];
      saveAs(url, "download.pdf");
      //print(url);
      seturlpath("");
    }
  }, [urlpath]);

  const setPdfName = (name, date) => {
    // 
    localStorage.setItem(
      "pdfname",
      name !== null ? name : "" + " " + date !== null ? date : ""
    );
  };
  return (
    <>
      {props.data && props.data.length > 0 ? (
        props.data.map((value, index) => (
          <ItemListRow key={index}>
            {
              value.isDocument ?
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ alignSelf: "center", alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                    {value.isInDraft === true ?
                      <NavLink
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/${value.token}`}

                      >
                        <Typography component={'span'} variant="h5">
                          {value.title}{" "}
                          {format(new Date(value.submitted_at), "dd MMM yyyy ")}

                        </Typography>
                      </NavLink>
                      :
                      <NavLink
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/pdf/${value.id}`}
                        onClick={setPdfName(
                          value.title,
                          format(new Date(value.submitted_at), "dd MMM yyyy ")
                        )}
                      >
                        <Typography component={'span'} variant="h5">
                          {value.title}{" "}
                          {format(new Date(value.submitted_at), "dd MMM yyyy ")}

                        </Typography>
                      </NavLink>

                    }
                    {value.isInDraft === true && (
                      <span
                        style={{
                          backgroundColor: "rgb(231,187,186)",
                          borderRadius: "7px",
                          padding: "10px",
                          marginRight: '15px'
                        }}
                      >
                        Entwurf
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={2}>

                    <IconButton href="mailto:example.com" disabled={value.isInDraft === true ? true : false}>
                      <EmailIcon />
                    </IconButton>
                    <IconButton id={value.id} onClick={getSummery} disabled={value.isInDraft === true ? true : false}>
                      <PrintIcon />
                    </IconButton>
                    {
                      value.isInDraft === true && (<IconButton id={value.id} onClick={deletedraft}>
                        <DeleteIcon />
                      </IconButton>)
                    }
                  </Grid>
                  <Grid item xs={4}>
                    <BigText>
                      Vor{" "}
                      {new Date().getMonth() +
                        1 -
                        format(new Date(value.submitted_at), "M")}{" "}
                      Monaten
                    </BigText>
                    <SmallDateAndTime>
                      {format(new Date(value.submitted_at), "MMM dd yyyy p ")}
                    </SmallDateAndTime>
                  </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ alignSelf: "center", alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={`/image/${value.id}`}
                      onClick={setPdfName(
                        value.title,
                        format(new Date(value.submitted_at), "dd MMM yyyy ")
                      )}
                    >
                      <Typography component={'span'} variant="h5">
                        {!!value.title ? value.title : "IMG"}
                      </Typography>
                    </NavLink>
                  </Grid>
                  <Grid item xs={2}>

                    <IconButton href="mailto:example.com" disabled={value.isInDraft === true ? true : false}>
                      <EmailIcon />
                    </IconButton>
                    <IconButton id={value.id} onClick={getSummery} disabled={value.isInDraft === true ? true : false}>
                      <PrintIcon />
                    </IconButton>

                  </Grid>
                  <Grid item xs={4}>
                    <BigText>
                      Vor{" "}
                      {new Date().getMonth() +
                        1 -
                        format(new Date(value.created_at), "M")}{" "}
                      Monaten
                    </BigText>
                    <SmallDateAndTime>
                      {format(new Date(value.created_at), "MMM dd yyyy p ")}
                    </SmallDateAndTime>
                  </Grid>
                </Grid>
            }
          </ItemListRow>
        ))
      ) : (
        null
      )}
    </>
  );
}

const DateDiv = styled.div`
  display: flex;
  align-items: center;
  color: #9d9b9b;
`;
const DivContainter = styled.div`
  display: block;
  padding: 15px 20px;
`;
const BulletContainer = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
`;

function TabsNavItemSecond(props) {

  return (
    <>
      <ItemListRow>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <Typography component={'span'} variant="h5" color="primary">
              {props.data.length > 0 && props.data[props.data.length - 1].title}{" "}
              {format(
                new Date(props.data.length > 0 && props.data[props.data.length - 1].submitted_at),
                "dd MMM yyyy "
              )}
            </Typography>
            <DateDiv>
              <CalendarTodayIcon fontSize="small" />

              <span className="ml-1">
                {" "}
                {format(
                  new Date(props.data.length > 0 && props.data[props.data.length - 1].submitted_at),
                  "MMM dd yyyy p "
                )}
              </span>
            </DateDiv>
          </Grid>
        </Grid>
      </ItemListRow>
      <DivContainter>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ alignSelf: "center" }}>
            <BulletContainer>
              <FiberManualRecordIcon
                fontSize="small"
                color="primary"
                className="pr-2"
              />
              Gesetzlichversichert
            </BulletContainer>
          </Grid>
          <Grid item xs={6} style={{ alignSelf: "center" }}>
            <BulletContainer>
              <FiberManualRecordIcon
                fontSize="small"
                color="primary"
                className="pr-2"
              />
              Pflichtversichert
            </BulletContainer>
          </Grid>
        </Grid>
      </DivContainter>
    </>
  );
}

const TabDiv = styled.div`
  background-color: #90ed8e;
  border: 1px solid #798772;
  border-radius: 10px;
  text-align: center;
  font-size: 24px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem 0 1.5rem;
`;

export default class ItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defautlsearchvalue: "",
      recordList: [],
      defaultList: [],
      firstName: "",
      surName: "",
      lastrecordAbnormalities: [],
      isLoading: false,
    };

    this.handlechange = this.handlechange.bind(this);
  }

  changeLoading = (value) => {
    this.setState({
      isLoading: value,
    });
  };

  async componentDidMount() {

    localStorage.setItem("patientId", window.location.pathname.replace("/itemDetails/", ""))
    GetdocumentslistForpatient(this);
    if (localStorage.getItem("siteInfo") === "current") {
      this.setState({
        // defaultList : this.state.recordList,
        firstName: !!localStorage.getItem("firstName") ? localStorage.getItem("firstName") : this.fetchuserdata(),
        surName: !!localStorage.getItem("surName") ? localStorage.getItem("surName") : this.fetchuserdata(),
      });
    }
    else {
      var response = await GetpatientDetail(this.state);
      this.setState({
        // defaultList : this.state.recordList,
        firstName: response.data.firstName,
        surName: response.data.surName,
      });

    }

  }

  handlechange = (e) => {
    this.setState({
      recordList: this.state.defaultList.filter((i) =>
        i.Name.toLowerCase().includes(e.target.value.toLowerCase())
      ),
      defautlsearchvalue: e.target.value.toLowerCase(),
    });
  };
  fetchuserdata = () => {
    alert("fetching...")
  }
  abortBtn = () => {
    this.setState({
      recordList: this.state.defaultList,
      defautlsearchvalue: "",
    });
  };
  deleteDraftRecord = async (id) => {
    this.changeLoading(true)
    let checkDelete = await deletedraftRecord(id);

    if (checkDelete === true) {
      let filteredlist = this.state.defaultList.filter((i) => i.id !== parseInt(id));

      this.setState({
        recordList: filteredlist,
      });
    }
    this.changeLoading(false)
  }
  render() {
    return (
      <>
        <Header
          backurl="/patientlist"
          backbtnTitle="Patienten auswahlen"
          leftButton="Back"
          title={`${this.state.firstName} ${this.state.surName}`}
          rightButton="setting"
        />
        <div className="p-3">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={3}>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to="/newAnamnesesheet"
              >
                <TabDiv>
                  <span> Neuer Anamnesesbogen</span>
                </TabDiv>
              </NavLink>
            </Grid>
            <Grid item xs={3}>
              <TabDiv onClick={this.getSummery}>
                <span>Protokollierte Beratung</span>
              </TabDiv>
            </Grid>
            <Grid item xs={3}>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to="/newDocumentList"
              >
                <TabDiv>
                  <span>Neues Dokument</span>
                </TabDiv>
              </NavLink>
            </Grid>
            <Grid item xs={3}>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to="/mediaList"
              >
                <TabDiv>
                  <span>Medien</span>
                </TabDiv>
              </NavLink>
            </Grid>
          </Grid>
        </div>
        <div>
          <TabsNav data={this.state.recordList} delDraft={this.deleteDraftRecord} loading={this.changeLoading} />
        </div>
        {this.state.isLoading === true ? (
          <AddLoader type="Bars" color="#015270" height={100} width={100} />
        ) : null}
      </>
    );
  }
}
