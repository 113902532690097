import React, { useState } from 'react'
import styled from "styled-components";
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import Header from "./header";
import print from 'print-js'
import { GetdocumentSummery } from "../api";
import AddLoader from "../components/Loader";
import Swal from 'sweetalert2';
import { PDFDocument } from 'pdf-lib'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const BackgroundColor = styled.div`
background-color:#ccc;
padding:15px;
padding-top: 150px;
`
const StickTop = styled.div`
position:fixed;
top:0;
width: 100%;
z-index: 9;
`
const RightPdfTopButton = styled.div`
text-align:right;
background-color:#fff;
padding:10px;
box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
`

function PdfViewer(props) {

    const [totalPages, setTotalPages] = useState(null);
    const [isLoading, setisLoading] = useState(false)
    const [pdfname , setPdfname] = useState("");
    const [pdfurl, setpdfurl] = useState([]);
    const [NewSinglePdfFile, setNewSinglePdfFile] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        
        setTotalPages(numPages);
    }

    React.useEffect(async() => {
        setisLoading(true)
        setPdfname(localStorage.getItem("pdfname"))
        var filepath = await GetdocumentSummery(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
        setisLoading(false);
        if(filepath==="Empty")
        {
          Swal.fire({
            title: 'Empty Template...',
            icon: 'info',
            confirmButtonText: 'Close'
          })
        }
        else
        {
            let arr = [];
            for (let i = 0; i < filepath.length; i++) {
                arr.push(filepath[i].replace(/\\/g, "/"))
                
            }
            setpdfurl(arr);
            
        }
        
        
        
    }, []);
    React.useEffect(() => {
        if(pdfurl.length > 0){

            printPDFS();
        }
    }, [pdfurl]);
   
    
    const  getSummery = async (e) => {
        if(pdfurl==="")
        {
            Swal.fire({
                title: 'Empty Template...',
                icon: 'info',
                confirmButtonText: 'Close'
              })
        }else{
                //  var filepath = await GetdocumentSummery(e.currentTarget.id,props);
                // var filepath = pdfurl.replace(/\\/g, "/");
                    
                //  alert(filepath)
               
                    print(NewSinglePdfFile)
                    
               
        }
      };


      const  printPDFS = async() => {
        /* Array of pdf urls */
        let pdfsToMerge = pdfurl;
       
        const mergedPdf = await PDFDocument.create();
        for (const pdfCopyDoc of pdfsToMerge) {
        const pdfBytes = await fetch(pdfCopyDoc).then(res => res.arrayBuffer())
        //const pdfBytes = fs.readFileSync(pdfCopyDoc);
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
       }
       const mergedPdfFile = await mergedPdf.save();
       convertMergedpdfToBlob(mergedPdfFile);
        }
     const   convertMergedpdfToBlob = (data) =>  {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url= window.URL.createObjectURL(blob);
            //window.open(url);
            setNewSinglePdfFile(url)
           }
    return (
        <>
         {isLoading === true ?  <AddLoader  type="Bars"
        color="#015270"
        height={100}
        width={100}
             /> : null}
            <StickTop>
                <Header
                    backbtnTitle="Zuruck"
                    leftButton="Back"
                    title={pdfname}
                    rightButton=""
                />
                <RightPdfTopButton>
                    <Button variant="outlined" color="primary" className="mr-3"  href="mailto:example.com" endIcon={<EmailIcon />}>
                        Per E-Mail versenden
                    </Button>
                    <Button variant="outlined" color="primary" onClick={getSummery}  endIcon={<PrintIcon />}>
                        Drucken
                    </Button>
                </RightPdfTopButton>
            </StickTop>
            <BackgroundColor>
                {!!pdfurl ? pdfurl.map((value,index) => 
                <Document
                    file={value}
                    onLoadSuccess={onDocumentLoadSuccess}

                >
                    {Array.apply(null, Array(totalPages))
                        .map((x, i) => i + 1)
                        .map(page => <Page pageNumber={page} width={window.innerWidth - 100} className="cenerit mb-4" />)}

                </Document> ) : null}
            </BackgroundColor>
        </>
    );
}

export default PdfViewer;