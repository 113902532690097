import React from "react";
import styled from "styled-components"
import Header from "../../components/header"
import { NavLink } from "react-router-dom";

const ListRow = styled(NavLink)`
    padding:1.5rem;
    font-size:20px;
    font-weight:500;
    display:block;
    border-bottom: 1px solid #ccc;
    text-decoration:none;
    color:#000;
    &:hover {
        background-color: #f8f8f8;
        cursor:pointer !important;
    }
    &:last-child {
        border-bottom: none
    }`

const NewAnamneseDocumentList = () => {
    return (
        <>
            <Header backbtnTitle="Zuruck" leftButton="Back" title="Anamnesebogen" rightButton="" />
            <ListRow to="/athenaAnamnesebogenlist">
                Athena-Anamnesebogen
            </ListRow>
            <ListRow to="/eigeneAnamnesebogenlist">
                Eigene Anamnesebogen
            </ListRow>
        </>
    )
}

export default NewAnamneseDocumentList