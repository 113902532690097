import { createContext } from 'react'


export const AppContext = createContext({
    CameraimgSource: "",
    ShowCameraFromForm:false,
    IsAuthorizeUser: false,
    // authorizeUser: false,
    IsCameraOn:false,
    ShowDrawPopup:false,
    signpadsPath: "",
    padId: "",
    source:"",
    setIsAuthorizeUser: (IsAuthorizeUser: boolean) => {},
    // authorizeUserEvent: (authorizeUser:boolean) => {},
    TriggerCameraEvent: (authorizeUser:boolean) => {},
    setCameraimgSource: (source:string) => {},
    setShowCameraFromForm: (show:boolean) => {},
    ShowDrawPopupEvent: (show:boolean) => {},
    setSignpadEvent: (padId:string,signpadsPath: string) => {},
  });