import React, { useState } from 'react'
import styled from "styled-components";
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import Header from "./header";
import print from 'print-js'
import { GetImageById, GetdocumentSummery } from "../api";
import AddLoader from "../components/Loader";
import Swal from 'sweetalert2';
import { PDFDocument } from 'pdf-lib'
import { Image } from 'react-konva';
import { useSearchParams } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const BackgroundColor = styled.div`
background-color:#ccc;
padding:15px;
padding-top: 150px;
`
const StickTop = styled.div`
position:fixed;
top:0;
width: 100%;
z-index: 9;
`
const RightPdfTopButton = styled.div`
text-align:right;
background-color:#fff;
padding:10px;
box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
`

function ImageViewer(props) {

    const [isLoading, setisLoading] = useState(false)
    const [imageObj, setimageObj] = useState(undefined);
    const getSummery = async (e) => {
       
            // print(NewSinglePdfFile)
    };

    React.useEffect(async () => {
        setisLoading(true)
        await GetImageById(window.location.href.substring(window.location.href.lastIndexOf('/') + 1), setimageObj);
        setisLoading(false);
    }, []);

    return (
        <>
            {isLoading === true ? <AddLoader type="Bars"
                color="#015270"
                height={100}
                width={100}
            /> : null}
            <StickTop>
                <Header
                    backbtnTitle="Zuruck"
                    leftButton="Back"
                    title={imageObj?.bvs_image_id}
                    rightButton=""
                />
                {/* <RightPdfTopButton>
                    <Button variant="outlined" color="primary" className="mr-3" href="mailto:example.com" endIcon={<EmailIcon />}>
                        Per E-Mail versenden
                    </Button>
                    <Button variant="outlined" color="primary" onClick={getSummery} endIcon={<PrintIcon />}>
                        Drucken
                    </Button>
                </RightPdfTopButton> */}
            </StickTop>
            <BackgroundColor>
                {
                    (!!imageObj && !!imageObj.source) &&
                    <img src={imageObj?.source} alt="Image" />
                }
            </BackgroundColor>
        </>
    );
}

export default ImageViewer;