// New Local Url's
// export const ServerUrl = 'https://localhost:5002';
// export const LocalServerUrl = 'https://localhost:44358';
// export const templateServer = 'https://localhost:5002/api/v1/AnamneseLearning'
// export const extendedsite = 'http://localhost:8003/'
// export const ImageServer = 'https://localhost:5002/'
// export const submissionServer = 'https://localhost:5002/api/public/v1/AnamneseLearning/submittemplate/'




// Client server
export const ServerUrl='https://api.altona.app'; 
export const LocalServerUrl='https://local.altona.app'; 
export const templateServer = 'https://api.altona.app/api/v1/AnamneseLearning' 
export const extendedsite ='https://intern.altona.app/'
export const ImageServer ='https://api.altona.app/'  
export const submissionServer ='https://api.altona.app/api/public/v1/AnamneseLearning/submittemplate/'


export const athenaUrl = 'https://dampsoft.de/athena'
export const devMode = false
//@ts-ignore
export const devStage = 'documentFlow' as DocumentFlowStage
export const devStepIndex = 0