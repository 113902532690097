import React, {
  useRef,
  useState,
  useEffect,
  Component,
  useContext,
} from "react";
import darkBaseTheme from "material-ui/styles/baseThemes/darkBaseTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { AppContext } from "../../Context/AppContext";
// import { ImageServer } from '../config'
import axios from "axios";

import ImgPreview from "./ImgPreview";
// import { Camera, CameraType } from 'react-camera-pro';
import Camera from "../../Camera";
// import "../cameraposition.css";
import { ImageServer } from '../../Config'
const cameraposition = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100vw",
  height: "100vh",
  position: "absolute",
  zIndex: 99,
};

export default class CameraPro extends Component {
  // const [numberOfCameras, setNumberOfCameras] = useState(0);
  // const [image, setImage] = useState<string | null>(null);
  // const [showImage, setShowImage] = useState<boolean>(false);
  // const camera = useRef<CameraType>(null);
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      getimg: "",
      OpenPopUp: false,
    };

    this.onCapture = this.onCapture.bind(this);
  }
  componentDidCatch(error, info) {
    console.log(info);
    console.log(error);
  }
  componentWillUnmount() {
    console.log("unmouted CameraPro");
  }
  onCapture = (e, img) => {
    this.state.getimg = img;
    let imgstrng = img;
    this.setState({
      getimg: imgstrng,
    });
    if (imgstrng !== "") {
      this.setState({
        OpenPopUp: true,
      });
    }
  };
  handleClose = () => {
    this.setState({
      OpenPopUp: false,
    });
  };
  componentWillUnmount() {
    console.log("unmount");
  }
  btnUsePhoto = (img) => {
    const {
      TriggerCameraEvent,
      ShowDrawPopupEvent,
      setShowCameraFromForm,
      setCameraimgSource,
    } = this.context;

    if (this.props.isFromform === true) {
      const data = {
        Logo: this.state.getimg,
      };
    debugger

      axios({
        method: "Post",
        url: ImageServer + "atn_editor_api/v1/document_templates/0/medias",
        data: data,
      }).then((response) => {
        setCameraimgSource(response.data);
      });
      this.setState({
        OpenPopUp: false,
      });
      setShowCameraFromForm(false);
      // ShowDrawPopupEvent(false)
    } else {
      this.props.sendBase64(this.state.getimg);
      this.setState({
        OpenPopUp: false,
      });
      TriggerCameraEvent(false);
      ShowDrawPopupEvent(true);
    }
    document.body.classList.remove("removeScroll");
  };

  render() {
    return (
      <>
        <div className="cameraPosition" style={cameraposition}>
          <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
            <Camera
              onCapture={this.onCapture}
              maxScale={5}
              scale={1}
              style={{ width: "100%", height: "100%" }}
              emulation={false}
            />
          </MuiThemeProvider>
        </div>
        <ImgPreview
          Preview={this.state.getimg}
          Open={this.state.OpenPopUp}
          handleClose={this.handleClose}
          handleUsePhote={this.btnUsePhoto}
        />
      </>
    );
  }
}
