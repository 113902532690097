import React, { useContext } from "react";
import axios from "axios";
import { ServerUrl, LocalServerUrl } from "./Config";
import { AppContext } from "./Context/AppContext";
import { useSearchParams } from "react-router-dom";


export async function LoginUser(e) {

  const data = {
    UserName: e.state.email,
    Password: e.state.password,
  };

  let autToken = await axios({
    method: "Post",
    url: ServerUrl + "/api/Login",
    //  Credential:true,
    data: data,
  }).then((response) => {

    if (response.status === 200) {
      //
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("settingNavigateTo", response.data.setting.navigateTo);
      localStorage.setItem("userId", response.data.userId);
      localStorage.setItem("practiceId", response.data.practiceId);
      localStorage.setItem("ip", response.data.ip);

      // sessionStorage.setItem('practiceid', JSON.stringify(response.data));

      return "Aouthorize";
    } else {
      return "Unaouthorize";
    }
  });

  return autToken;
}

export function getAnamnesebögen(e) {

  const token = JSON.parse(localStorage.getItem("token"));
  debugger
  var url=e.state.templateTypeId == 1 ? "/api/AnamneseLearning/systemtemplates" : "/api/AnamneseLearning/owntemplates";
  axios({
    method: "Get",
    url: ServerUrl +url,
    headers: {
      Authorization: "Bearer " + token,
      userId: localStorage.getItem("userId"),
      // catagoryid: e.state.templateTypeId
    },
  }).then((response) => {
    debugger
    response.data.map(
      (value, index) => (value.languages = JSON.parse(value.languages))
    );
    e.setState({
      recordList: response.data,
      defaultList: response.data,
    });
  }).catch(function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export function getpatientlist(e) {
  const token = JSON.parse(localStorage.getItem("token"));
  axios({
    method: "Get",
    //url: ServerUrl + "/api/AnamneseLearning",
    url: 'https://' + e.state.ip + '/api/AnamneseLearning',
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {

    e.setState({
      recordList: response.data,
      defaultList: response.data,
    });
  }).catch(function (error) {
    if (!!error.response && error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export function getOwnTemplatelist(e) {
  const token = JSON.parse(localStorage.getItem("token"));
  axios({
    method: "Get",
    url: ServerUrl + "/api/AnamneseLearning/owntemplates",
    headers: {
      Authorization: "Bearer " + token,
      userId: localStorage.getItem("userId")
    },
  }).then((response) => {

    e.setState({
      recordList: response.data,
      defaultList: response.data,
    });
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export function getSystemTemplatelist(e) {
  const token = JSON.parse(localStorage.getItem("token"));
  axios({
    method: "Get",
    url: ServerUrl + "/api/AnamneseLearning/systemtemplates",
    headers: {
      Authorization: "Bearer " + token,
      userId: localStorage.getItem("userId")
    },
  }).then((response) => {

    e.setState({
      recordList: response.data,
      defaultList: response.data,
    });
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export function GetOwnAnamnesetemplate(e) {
  const token = JSON.parse(localStorage.getItem("token"));
  axios({
    method: "Get",
    url: ServerUrl + "/api/AnamneseLearning/OwnAnamnesetemplate",
    headers: {
      Authorization: "Bearer " + token,
      userId: localStorage.getItem("userId")
    },
  }).then((response) => {

    e.setState({
      recordList: response.data,
      defaultList: response.data,
    });
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export function GetsystemAnamnesetemplate(e) {
  const token = JSON.parse(localStorage.getItem("token"));
  axios({
    method: "Get",
    url: ServerUrl + "/api/AnamneseLearning/systemAnamnesetemplate",
    headers: {
      Authorization: "Bearer " + token,
      userId: localStorage.getItem("userId")
    },
  }).then((response) => {

    e.setState({
      recordList: response.data,
      defaultList: response.data,
    });
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export function GetdocumentslistForpatient(e) {
  const token = JSON.parse(localStorage.getItem("token"));
  axios({
    method: "Get",
    //url: ServerUrl + "/api/AnamneseLearning/documents/" + localStorage.getItem("patientId"),
    url: "https://" + localStorage.getItem("ip") + "/api/AnamneseLearning/documents/" + localStorage.getItem("patientId"),
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    e.setState({
      recordList: response.data,
      defaultList: response.data,
      lastrecordAbnormalities: response.abnormalities,
    });
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export async function GetImageById(id, setimageObj) {
  const token = JSON.parse(localStorage.getItem("token"));
  await axios({
    method: "Get",
    //url: ServerUrl + "/api/AnamneseLearning/documents/" + localStorage.getItem("patientId"),
    url: "https://" + localStorage.getItem("ip") + "/api/Images/getImage/" + id,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    debugger
    setimageObj(response.data)
  }).catch(function (error) {

  });
}
export async function GetdocumentSummery(medicalhistoryId, e) {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios({
    method: "Get",
    // url: ServerUrl + "/api/AnamnesisParser/" + medicalhistoryId,
    url: "https://" + localStorage.getItem("ip") + "/api/AnamnesisParser/" + medicalhistoryId,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    if (response.data === "Empty") {
      return response.data;
    }
    let newPdfArray = [];
    for (let i = 0; i < response.data.length; i++) {
      //newPdfArray.push(ServerUrl + "/" + response.data[i].trim())
      newPdfArray.push("https://" + localStorage.getItem("ip") + "/" + response.data[i].trim())
    }
    // let filepath = ServerUrl + "/" + response.data.trim();
    return newPdfArray;
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export async function deletedraftRecord(medicalhistoryId, props) {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios({
    method: "Post",
    url:
      ServerUrl + "/api/v1/AnamneseLearning/DeleteHistory/" + medicalhistoryId,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {

    return response.data;
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export async function SaveImage(image) {
  const token = JSON.parse(localStorage.getItem("token"));
  var obj = {
    pvs_patid: localStorage.getItem("patientId"),
    source: image,
    data: image,
  }
  return await axios({
    url: "https://" + localStorage.getItem("ip") + "/api/Images/create",
    method: "post",
    data: obj,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    return response.data;
  }).catch(function (error) {
    if (!!error.response && error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export async function createPatient(values) {
  const token = JSON.parse(localStorage.getItem("token"));
  var patient = {
    salutation: values.salutation,
    title: values.title,
    first_name: values.firstName,
    last_name: values.lastName,
    date_of_birth: values.dob,
    gender: values.gender,
    address1: values.address,
    address2: values.address2,
    zipcode: values.postalcode,
    city: values.city,
    country: values.country,
    home_phone: values.phone,
    work_phone: values.phone2,
    cellular_phone: values.mobilenum,
    fax: values.fax,
    email: values.email,
  };
  return await axios({
    method: "Post",
    url: ServerUrl + "/api/v1/AnamneseLearning/createPatient/",
    data: patient,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {

    localStorage.setItem("patientId", response.data);
    return response.data;
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}

export async function GetAppOptions() {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios({
    method: "Get",
    url: ServerUrl + "/api/v1/AnamneseLearning/get_app_options/" + localStorage.getItem("practiceId"),
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {

    return response.data;
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}

export function SaveAppOptions(props) {
  const token = JSON.parse(localStorage.getItem("token"));
  let data = {
    Id: props.practiceId,
    AllowPriviousEntry: props.AllowPreviousEntries,
    NavigateTo: props.reirectloaction,
    BugReports: props.sendBugReports,
    Sendanalyticsdata: props.analysisData,
  };
  axios({
    method: "Post",
    url: ServerUrl + "/api/v1/AnamneseLearning/app_options/",
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data,
  }).then((response) => {

    if (response.data == true) {
      localStorage.setItem("settingNavigateTo", props.reirectloaction);
    }
  }).catch(function (error) {

    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}
export async function GetpatientDetail(props) {
  const token = JSON.parse(localStorage.getItem("token"));
  return await axios({
    method: "Get",
    //url: ServerUrl + "/api/AnamneseLearning/patientDetail/" + localStorage.getItem("patientId"),
    url: "https://" + localStorage.getItem("ip") + "/api/AnamneseLearning/patientDetail/" + localStorage.getItem("patientId"),
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    return response;
  }).catch(function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.reload()
    }
  });
}

export async function CsrfTokenTest(props) {
  return await axios({
    method: "Get",
    url: ServerUrl + "/api/account/tokenTestMethod?Id=1",
    headers: {

    },
  }).then((response) => {
    alert('Success')
  }).catch(function (error) {
    alert('Error')
  });
}