export interface FieldMapping {
  atnKey: string
  formKeys: string[]
}

export const resolveFieldMapping = (object: any, keys: string[]) => {
  let result = []
  for (let key of keys) {
    if (object.hasOwnProperty(key)) {
      result.push(object[key])
    }
  }
  return result.join(' ')
}
