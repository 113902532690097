import React, { Component} from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styled from 'styled-components'
import {LoginUser} from "../api";
import { AppContext } from "../Context/AppContext";
import { Navigate } from "react-router-dom";
const CenterForm = styled.div`
  
width:350px;
margin: auto;
height: 100vh;
display: flex;
align-items: center;
flex-direction:column;
justify-content:center
`
const TextContainer = styled.div`
margin-bottom: 40px;
`
const AthenaLoginText = styled.div`
font-size: 81px;
color:#015270;
text-transform: uppercase;
font-weight: 500;


`
const AthenaLoginSemiText = styled.div`
font-size: 25px;
text-transform: uppercase;
font-weight: normal;
line-height: 1px;
`


export default class Login extends Component {
  // state = {
  //   email: "",
  //   password: ""
  // };
  
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      iserror:'none'
    }
    this.login = this.login.bind(this);

}
// componentDidMount(){
//   this.context.setTokenEvent('Unaouthorize');
// }
  handleChange = e => {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
  };
  async login(e) {
   let token = await LoginUser(this);
   if(token==='Aouthorize')
   {
    // this.context.setTokenEvent(token);
    // this.context.authorizeUserEvent(true);
    this.state.iserror='none'
    this.context.setIsAuthorizeUser(true)

   }else{
    this.context.setIsAuthorizeUser(false)
   }

  }
  render() {
    
    return (
    
        <CenterForm>
              {JSON.parse(localStorage.getItem('token'))!==null && (
                <Navigate to="/" replace={true} />
              )}
          <TextContainer>
          <AthenaLoginText>
            Athena
          </AthenaLoginText>
          <AthenaLoginSemiText>
            Aufklarung & anamnese
          </AthenaLoginSemiText>
          </TextContainer>
            <form className="form" >
                <TextField 
                className="mb-3" 
                size="small" 
                id="email" 
                type="text"  
                fullWidth={true} 
                onChange={this.handleChange} 
                label="Email" 
                variant="outlined" 
                />
                <TextField
                id="password"
                label="Password"
                fullWidth={true}
                type="password"
                autoComplete="current-password"
                onChange={this.handleChange}
                variant="outlined"
                className="mb-3"
                size="small"

                />
            <p  style={{color:'red',display:this.state.iserror}}>Invalid Email or Password</p>
            <Button variant="contained" fullWidth color="primary" onClick={this.login} className="form__custom-button">
              Anmelden
            </Button>
        
            </form>
        </CenterForm>
    );
  }
}
