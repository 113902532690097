import { createContext } from 'react'
import { Practice } from '../types/Practice'
import { FieldMapping, resolveFieldMapping } from '../utils/fieldMappingHelpers'

export const PracticeContext = createContext({
  name: '',
  address1: '',
  address2: '',
  zip_code: '',
  city: '',
  email: '',
  phone: '',
  logo_url: '',
  website: '',
  dgsvo_data_protection_officer_address: '',
  dgsvo_data_protection_officer_contact: '',
  dgsvo_data_protection_officer_name: '',
  dgsvo_factoring_provide: '',
  loaded: false,
  setPractice: (practice: Practice) => {},
})

const practiceFieldMap: FieldMapping[] = [
  { atnKey: 'praxis_logo', formKeys: ['logo_url'] },
  { atnKey: 'praxis_name', formKeys: ['name'] },
  { atnKey: 'praxis_adresse_1', formKeys: ['address1'] },
  { atnKey: 'praxis_postleitzahl', formKeys: ['zip_code'] },
  { atnKey: 'praxis_stadt', formKeys: ['city'] },
  { atnKey: 'praxis_telefon', formKeys: ['phone'] },
  { atnKey: 'praxis_email', formKeys: ['email'] },
  {
    atnKey: 'praxis_dsgvo_factoring_provider',
    formKeys: ['dgsvo_factoring_provider'],
  },
  {
    atnKey: 'praxis_datenschutzbeauftragter',
    formKeys: ['dgsvo_data_protection_officer_name'],
  },
  {
    atnKey: 'praxis_datenschutzbeauftragter_adresse',
    formKeys: ['dgsvo_data_protection_officer_address'],
  },
  {
    atnKey: 'praxis_datenschutzbeauftragter_kontaktdaten',
    formKeys: ['dgsvo_data_protection_officer_contact'],
  },
]

export const replacePracticeContent = (content: string, practice: Practice) => {
  for (let mapping of practiceFieldMap) {
    content = content.replace(
      `{{${mapping.atnKey}}}`,
      resolveFieldMapping(practice, mapping.formKeys),
    )
  }
  return content
}
