import React from 'react'
import { Button } from '@material-ui/core'

interface Props {
  title: JSX.Element | string
  type?: 'button' | 'submit' | 'reset' | undefined
  action?: () => void
  href?: string
  primary?: boolean
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
}

export const ActionButton = (props: Props) => (
  <Button
    type={props.type}
    variant={props.primary ? 'contained' : 'outlined'}
    size={props.size || 'large'}
    onClick={props.action}
    href={props.href}
    color={props.primary ? 'primary' : 'default'}
    disabled={props.disabled ? true : false}>
    {props.title}
  </Button>
)
