import React, { useState, useEffect, useContext } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { TransferWithinAStationSharp } from "@material-ui/icons";
import { getSystemTemplatelist } from "../../api";
import { AppContext } from "../../Context/AppContext";
import { NavLink } from "react-router-dom";
import Header from "../../components/header";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { extendedsite } from "../../Config";

// import {AppContext} from '../Context/AppContext'
const SearchField = {
  backgroundColor: "#dee1dd",
  borderRadius: "10px",
  borderBottom: "none",
  color: "rgb(86,90,88)",
};
const Searchrow = {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #ccc",
};

const RecordName = styled.div`
  font-size: 26px;
`;

const Nostyle = {
  color: "#000",
  textDecoration: "none",
};

const ListRow = styled.div`
  padding: 1.5rem;
  font-weight: 400;
  display: block;
  border-bottom: 1px solid #ccc;
  text-decoration: none;
  color: #000;
  &:hover {
    background-color: #f8f8f8;
    cursor: pointer !important;
  }
  &:last-child {
    border-bottom: none;
  }
`;

// function ShowList(props) {
//     let list = props.recordlist;

//     const LinkClick = (e,title,id) => {
//         // e.preventDefault();
//         localStorage.setItem('infosheettitle', title);
//     }

//     return (
//         <>

//         {list !== null ? list.map((value,index) =>
//         <ListRow className="p-4"  key={index} onClick={(e) => LinkClick(e,value.title,value.id)}>
//              <NavLink style={Nostyle} to={"/"+value.id} >
//              <RecordName>
//                             {value.title}
//                         </RecordName>

//             </NavLink>
//         </ListRow>

//             ): <p>No record</p>}
//        </>
//     )
// }

const RecordRow = {
  display: "flex",
  alignItems: "center",
};
const RecordSubtitle = styled.div`
  font-size: 12px;
  color: #4a4a4a;
`;
const BottomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #015270;
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
`;
const CheckCounter = styled.div`
  font-size: 20px;
  color: #fff;
`;
const FooterGreenButton = styled.a`
  background-color: #90ed8e;
  border: 1px solid #798772;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  margin-right: 25px;
  padding: 1rem;
  color: #000;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;
function ShowList(props) {
  let list = props.recordlist;

  const [counter, setcounter] = useState(0);
  const [checkid, setcheckid] = useState([]);

  const checkboxChange = (e) => {
    e.target.checked === true
      ? setcounter(counter + 1)
      : setcounter(counter - 1);
    e.target.checked === true
      ? setcheckid([...checkid, e.target.id])
      : setcheckid(
          checkid.filter(function (value) {
            return value !== e.target.id;
          })
        );
  };
  useEffect(() => {
    localStorage.setItem("selectedTemplates", checkid);
  }, [checkid]);

  //   const LinkClick = (e, fName, lName, id) => {
  //     // e.preventDefault();
  //     localStorage.setItem("firstName", fName);
  //     localStorage.setItem("surName", lName);
  //     localStorage.setItem("patientId", id);
  //   };

  const setsiteinfo = () => {
    localStorage.removeItem("siteInfo");
  };
  return (
    <div style={{ paddingBottom: "70px" }}>
      {list !== null ? (
        list.map((value, index) => (
          <div
            className="p-4"
            style={{ borderBottom: "1px solid #ccc" }}
            key={index}
            // onClick={(e) => LinkClick(e)}
          >
            {/* <NavLink style={Nostyle} to={"/itemDetails/"+value.patientID} > */}
            <div style={RecordRow}>
              <div
                className=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      onChange={(e) => checkboxChange(e)}
                      checkedIcon={<CheckCircleIcon color="primary" />}
                      id={value.id}
                      name={`checkrow${index}`}
                      color="primary"
                    />
                  }
                />
                <div style={{ marginLeft: "10px" }}>
                  <RecordName>{value.title}</RecordName>
                  <div>
                    {/* <RecordSubtitle>
                        Verfugbare Sprachen:{" "}
                        {value.languages.map((langvalue, langindex) =>
                          langindex !== value.languages.length ? langvalue : " |"
                        )}
                      </RecordSubtitle> */}
                  </div>
                </div>
              </div>
            </div>

            {/* </NavLink> */}
          </div>
        ))
      ) : (
        <p>No record</p>
      )}

      <BottomFooter>
        <CheckCounter>{counter} Vorlagen ausgewahlt</CheckCounter>
        {checkid.length > 0 ? (
          <FooterGreenButton
            onClick={setsiteinfo}
            href={
              `${extendedsite}` +
              "patient/" +
              `${localStorage.getItem("patientId")}` +
              "/" +
              `${checkid}`+'|'+`${localStorage.getItem("practiceId")}`+
              "/ip/" +
              localStorage.getItem("ip")
            }
          >
            Ausfullen durch Patient starten
          </FooterGreenButton>
        ) : (
          <FooterGreenButton>Ausfullen durch Patient starten</FooterGreenButton>
        )}
        {/* <FooterGreenButton onClick={setsiteinfo}   href={`${extendedsite}`+'patient/'+`${localStorage.getItem("patientId")}`+'/'+`${checkid}`}>
            Ausfullen durch Patient starten
          </FooterGreenButton>
   */}
        {/* <a
                 style={{marginRight:"8px"}}
                 href={`${extendedsite}`+`${localStorage.getItem("selectedTemplates")}`}
                 className="btn btn-primary"
               >
                 Ausfullen durch Patient starten
               </a> */}
      </BottomFooter>
    </div>
  );
}

export default class AthenaInfoSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defautlsearchvalue: "",
      recordList: [],
      defaultList: [],
    };

    this.handlechange = this.handlechange.bind(this);
  }

  componentDidMount() {
    // const {token} = this.context;

    getSystemTemplatelist(this);
  }

  handlechange = (e) => {
    this.setState({
      recordList: this.state.defaultList.filter((i) =>
        i.title.toLowerCase().includes(e.target.value.toLowerCase())
      ),
      defautlsearchvalue: e.target.value.toLowerCase(),
    });
  };
  abortBtn = () => {
    this.setState({
      recordList: this.state.defaultList,
      defautlsearchvalue: "",
    });
  };

  render() {
    return (
      <>
        <Header
          leftButton="Back"
          backbtnTitle="Dokumentvorlagen"
          title="Dokumentvorlagen"
          rightButton=""
        />
        <div style={Searchrow}>
          <div className="p-2" style={{ width: "90%" }}>
            <TextField
              type="text"
              id="searchBar"
              style={SearchField}
              fullWidth={true}
              onChange={(e) => this.handlechange(e)}
              value={this.state.defautlsearchvalue}
              placeholder="Vorlagen Suchen"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "rgb(86,90,88)", paddingLeft: "5px" }}
                    />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
          </div>
        </div>
        <ShowList recordlist={this.state.recordList} />
      </>
    );
  }
}
