import React, { useContext, useEffect,useState , useRef } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisSubmission } from '../../types/AnamnesisSubmission'
import { Container, Grid } from '@material-ui/core'
import { Form, Formik,useFormik  } from 'formik'
import { FormError } from '../../components/FormError'
import { FormFocusError } from '../../components/FormFocusError'
import { getFixedSteps } from './fixedSteps'
import { Loading } from '../Loading'
import { StepButtons } from '../../components/StepButtons'
import { StepContent } from '../../components/StepContent'
import { Stepper } from '../../components/Stepper'
import { submissionServer } from '../../Config'
import { useIntl } from 'react-intl'
import { DocumentTemplate } from '../../Pages/DocumentTemplate'
import Header from "../../components/header"
import Swal from 'sweetalert2'

import {
  Routes,
  Route,
  Link,
  useLocation,
  Navigate,
  Outlet
} from "react-router-dom";
import {
  DocumentFlowContext,
  getSteps,
} from '../../Context/DocumentFlowContext'
import {
  assembleSubmission,
  generateInitialFormValues,
  generateInitialConsentValues,
  generateInitialFixedStepValues,
} from '../../utils/formHelpers'
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import SuperPassword from "../../components/SuperPassword"
import { useNavigate } from 'react-router-dom';
const axios = require('axios');


interface Props {
  extraSubmissionData: { [key in string]?: {} }
}


const BottomFooter = styled.div`
position:fixed;
bottom:0;
width:99%;
padding : 5px ;
border-top:1px solid #ccc;
background-color:#fff;
display:block;
align-items:center
`
const AbortBtn = styled.button`
padding: 15px;
border:1px solid rgb(220, 0, 41);
color: rgb(220, 0, 41);
background-color:transparent;
height:100%;
cursor:pointer;
display:block;
width:100%;
border-radius:5px;
margin-right:15px;
font-size: 18px;
&:hover{
  background-color: rgba(220, 0, 41,.9);
  color:white
}
`
const SaveBtn = styled.button`
padding: 15px;
border:1px solid #798772;
color: #798772;
background-color:#90ed8e;
height:100%;
cursor:pointer;
display:block;
width:100%;
border-radius:5px;
margin-right:0px;
font-size: 18px;

&:hover{
  background-color: #90ed8e;
  color:white
}
`
const SaveDraftBtn = styled.button`
padding: 15px;
border:1px solid #a19c9c;
color: #a19c9c;
background-color:transparent;
height:100%;
cursor:pointer;
display:block;
width:100%;
border-radius:5px;
margin-right:15px;
font-size: 18px;

&:hover{
  background-color: #eee;
  color:#a19c9c
}
`
const ResetBtn = styled.button`
padding: 15px;
border:1px solid rgb(220, 0, 41);
color: rgb(220, 0, 41);
background-color:transparent;
height:100%;
cursor:pointer;
display:block;
width:100%;
border-radius:5px;
margin-right:15px;
font-size: 18px;

&:hover{
  background-color: rgba(220, 0, 41,.9);
  color:white
}
`



export const DocumentFlow = ({ extraSubmissionData }: Props) => {
  let location = useLocation();
  let navigation = useNavigate();

  const { formatMessage } = useIntl()
  const [superpasspopup, setsuperpasspopup] = useState(false);
  const [formdata, setformdata] = useState();
  const [draft, setdraft] = useState(false);
  const [btnAbort, setbtnAbort] = useState(false);



  const { templates, setStage, stepIndex, setStepIndex } = useContext(
    DocumentFlowContext,
  )

  const fixedSteps = getFixedSteps(formatMessage)
  const steps = getSteps(fixedSteps, templates)
  let initialPatientValues = generateInitialFixedStepValues(fixedSteps[0])

  const [{ loading, error }, submitForm] = useAxios<AnamnesisSubmission>(
    {
      url: `${submissionServer}`,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    },
    {
      manual: true,
    },
  )
const showSuperPassPopup = (data:any) => {
  setsuperpasspopup(true)
  setformdata(data)
  setbtnAbort(true)
}
const fnshowSuperPassPopup = (data:any) => {
  setsuperpasspopup(true)
  setformdata(data)
  setbtnAbort(false)
}
const fnsetdraftflase = () => {
  setdraft(false)
}

const fnsetdrafttrue = () => {
  setdraft(true)
}

  useEffect(() => {
    window.scrollTo(0, 0)

  })




  
  if (loading) return <Loading />

  return (
    <>
      <Header leftButton="Back" backbtnTitle="Zuruck" title={localStorage.getItem('infosheettitle') !== undefined ? localStorage.getItem('infosheettitle') : ""} rightButton="" />

      <Formik 
     
        initialValues={{
          ...initialPatientValues,
          ...generateInitialFormValues(templates),
          ...generateInitialConsentValues(templates),
        }}
        // validationSchema={steps[0].validationSchema}
        onSubmit={(values, formikHelpers) => {
          // if (stepIndex < steps.length - 1) {
          //   setStepIndex(stepIndex + 1)
          //   formikHelpers.setTouched({})
          //   formikHelpers.setSubmitting(false)
          // } else {
          const data = {
            ...assembleSubmission(values as any, templates),
            ...extraSubmissionData,
          }
          const token  = JSON.parse(localStorage.getItem('token') || "");
          axios({
            method: 'Post',
            url: submissionServer+localStorage.getItem('patientId'),
            headers: {
              'Authorization': 'Bearer '+token,
              'formData':JSON.stringify(data),
              'draft':draft,
              'title':localStorage.getItem('infosheettitle')
            },
            data: data,
          }).then((result : any) => {
            if(!result.data){
              Swal.fire({
                title: 'Error!',
                text: 'Something went wrong...',
                icon: 'error',
                confirmButtonText: 'Close'
              })
            }else{
              fnshowSuperPassPopup(data)
            }

            console.log(data)
            // navigation("/itemDetails/"+localStorage.getItem('patientId'));
          });

        
        }}>
       
        <Form className="pb-5">
          {error && <FormError />}
          <Grid container direction="column">
            <DocumentTemplate />
          </Grid>
          {/* <Container maxWidth="md">
            <StepButtons
              stepIndex={stepIndex}
              steps={steps}
              setStepIndex={setStepIndex}
            />
          </Container> */}
          <FormFocusError />
          <BottomFooter>
           <div style={{ flexGrow: 1 }}>
             <Grid container spacing={3}>
   
               <Grid item xs={6} sm={3}>
                 <AbortBtn type="button" onClick={showSuperPassPopup}>
                   Abbrechen
                 </AbortBtn>
               </Grid>
               <Grid item xs={6} sm={3}>
                 <ResetBtn type="reset">
                   Zurucksetzen
                 </ResetBtn>
               </Grid>
               <Grid item xs={6} sm={3}>
                 <SaveDraftBtn onClick={fnsetdrafttrue} type="submit">
                   Entwurf Speichern
                 </SaveDraftBtn>
               </Grid>
               <Grid item xs={6} sm={3}>
                 <SaveBtn onClick={fnsetdraftflase} type="submit">
                   Speichern
                 </SaveBtn>
               </Grid>
             </Grid>
           </div>
     
             </BottomFooter>
          {/* { location.state && location.state.fromClient  === true ? 
         
         
          <BottomFooter>
          <div style={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
  
              <Grid item xs={6} sm={4}>
                <AbortBtn type="button" onClick={showSuperPassPopup}>
                  Abbrechen
                </AbortBtn>
              </Grid>
              <Grid item xs={6} sm={4}>
                <ResetBtn type="reset">
                  Zurucksetzen
                </ResetBtn>
              </Grid>
            
              <Grid item xs={6} sm={4}>
                <SaveBtn  type="submit">
                  Speichern
                </SaveBtn>
              </Grid>
            </Grid>
          </div>
    
            </BottomFooter>
           : 
           <BottomFooter>
           <div style={{ flexGrow: 1 }}>
             <Grid container spacing={3}>
   
               <Grid item xs={6} sm={3}>
                 <AbortBtn type="button" onClick={showSuperPassPopup}>
                   Abbrechen
                 </AbortBtn>
               </Grid>
               <Grid item xs={6} sm={3}>
                 <ResetBtn type="reset">
                   Zurucksetzen
                 </ResetBtn>
               </Grid>
               <Grid item xs={6} sm={3}>
                 <SaveDraftBtn onClick={fnsetdrafttrue} type="submit">
                   Entwurf Speichern
                 </SaveDraftBtn>
               </Grid>
               <Grid item xs={6} sm={3}>
                 <SaveBtn onClick={fnsetdraftflase} type="submit">
                   Speichern
                 </SaveBtn>
               </Grid>
             </Grid>
           </div>
     
             </BottomFooter>
           } */}
        </Form>
      </Formik>
      <SuperPassword open={superpasspopup}/>
     
    </>
  )
}
