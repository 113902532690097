import React, { useState } from "react";
import styled from "styled-components"
import DrawOnImage from "./DrawOnCameraCaptureimg"
import Header from "../../components/header"

const ListRowwithImg = styled.a`
    padding:1rem;
    font-size:20px;
    font-weight:500;
    display:flex;
    border-bottom: 1px solid #ccc;
    align-items: center;

    &:hover {
        background-color: #f8f8f8;
        cursor:pointer
    }
    &:last-child {
        border-bottom: none
    }
    `
    const Listimg = styled.img`
    width:70px;
    height:70px;
    margin-right:15px;
    `
    const ListText = styled.p`
    font-size:24px;
    font-weight: 400;

    `

 const Illustration = () => {
     const [imgpath , setimgpath] = useState("");
     const [EditedImg , setgetEditedImg] = useState("");
     const [open , setopen] = useState(false);

    const ListData = [
        {
            imgsrc:"https://thumbs.dreamstime.com/b/human-tooth-structure-description-vector-illustration-human-tooth-structure-cut-view-diagram-modern-flat-design-111048650.jpg",
            text: "Zahnschema eines gesunden Zahns",
        },
        {
            imgsrc:"https://www.mayoclinic.org/-/media/kcms/gbs/patient-consumer/images/2013/08/26/10/43/an00355_im03923_de7_baby_teeth_thu_jpg.jpg",
            text: "Zahnreihenschema",
        },
        {
            imgsrc:"https://zahnfokus.de/images/leistungen/wurzelspitze_schema.jpg",
            text: "Kieferschema",
        }
];

const GetEditedImg = (img) => {
    setgetEditedImg(img)
}
const imgClick = (e) => {
    setimgpath(e.target.getAttribute("value"));
    setopen(true)
}
    return(
        <>
                                                  <Header backbtnTitle="Medien" leftButton="Back" title="Illustrationen" rightButton="" />

        {ListData.map((i,index) => 
            <ListRowwithImg key={index} onClick={imgClick} value={i.imgsrc} >
            <Listimg src={i.imgsrc} />
            <ListText>
               {`${i.text}`} 
            </ListText>
            </ListRowwithImg>

        )}
           {open === true ? <DrawOnImage getimg={GetEditedImg} setBaseUrl={imgpath} triggerbycontext={false} showPopup={open} setshowpop={setopen} /> : null }
        </>
    )
}
export default Illustration;