import React, { PureComponent, useContext } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { colors, theme } from '../utils/theme'
import { getPatient, replacePatientContent } from '../Context/PatientContext'
import { PracticeLogo } from './PracticeLogo'
import Button from '@material-ui/core/Button';
import { ImageServer } from '../Config'
import {
  ATNValueType,
  HeadingType,
  GroupType,
  ListType,
  ParagraphType,
  TextType,
  SeparatorType,
  SpacerType,
  ImageType,
  TextareaType,
  TextInputType,
  SelectOneType,
  SelectMultipleType,
  SignatureType,
  VideoType,
  YesNoType,
  LanguageType,
  SupportedATNInputType,
  ArrangementType,
  RadioButtonType,
  ContentType,
  CheckboxType,
  CameraImageType,
} from '../types/ATNTypes'
import { PatientInterface } from '../types/Patient'
import { Practice } from '../types/Practice'
import {
  FieldProps,
  FastField,
  useFormikContext,
  Field,
  ErrorMessage,
  useField,
  FormikContextType,
} from 'formik'
import {
  PracticeContext,
  replacePracticeContent,
} from '../Context/PracticeContext'
import { AppContext } from '../Context/AppContext'

import {
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormGroup,
  TextareaAutosize,
} from '@material-ui/core'
import {
  assembleElementFormId,
  getNestedElement,
  getElementPath,
  getValuesForOptionIds,
} from '../utils/formHelpers'
import { FormattedMessage } from 'react-intl'
import { FormContent } from './FormContent'
import DatePicker from './DatePicker'
import Label from '../styled/Label'
import RequiredStar from '../styled/RequiredStar'
import {CameraContext} from '../Context/CameraContext'
import SavedSignature from "../Pages/SignaturePadsInForm";
import DrawableImageInForm from "../Pages/DrawableImageInForm"

const ATN_KEY_TODAY = 'datum_heute'
interface CSSProps {
  align?: string
  size?: string
}

const HeadingElement = styled.div<CSSProps>`
  text-align: ${({ align }) => align};
  color: ${colors.blue};

  h1 {
    font-size: 30px;
    font-weight: 400;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
  h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    border-bottom: 2px solid ${colors.lightGrey};
  }
`

const replaceContent = (
  content: string | undefined,
  activeLang: string,
  patient: PatientInterface,
  practice: Practice,
) => {
  content = content || ''
  content = content.replace(`{{${ATN_KEY_TODAY}}}`, moment().format('L'))
  content = replacePatientContent(content, patient, activeLang)
  return replacePracticeContent(content, practice)
}

interface HeadingProps {
  activeLang: string
  element: HeadingType
}

const Heading = ({ activeLang, element: { content, style } }: HeadingProps) => {
  const { headingSize, ...rest } = style

  return (
    <HeadingElement>
      {React.createElement('h' + headingSize, rest, content[activeLang])}
    </HeadingElement>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
const Col = styled.div`
  text-align: left;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 10px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0;
  }
`

interface GroupProps {
  element: GroupType
  activeLang: string
  templateId: string
}

const Group = ({ activeLang, element, templateId }: GroupProps) => (
  <Row>
    {element.children.map((child) => (
      <Col key={child.id}>
        <FormElement
          activeLang={activeLang}
          element={child}
          templateId={templateId}
        />
      </Col>
    ))}
  </Row>
)

const Ol = styled('ol')`
  font-size: 18px;
  margin: 20px 0;
  padding-inline-start: 20px;
`
const Ul = styled('ul')`
  list-style-type: disc;
  font-size: 18px;
  margin: 20px 0;
  padding-inline-start: 20px;
`
const Li = styled('li')`
  margin: 5px 0;
`

interface ListProps {
  element: ListType
  activeLang: string
}

const List = ({ activeLang, element }: ListProps) =>
  element.style.order === 'ordered' ? (
    <Ol>
      {element.items.map((item) => (
        <Li key={item.id}>{item.content[activeLang]}</Li>
      ))}
    </Ol>
  ) : (
    <Ul>
      {element.items.map((item) => (
        <Li key={item.id}>{item.content[activeLang]}</Li>
      ))}
    </Ul>
  )

interface TextProps {
  element: TextType
  activeLang: string
}
const Text = ({ activeLang, element }: TextProps) => {
  const patient = getPatient(useFormikContext().values)
  const practice = useContext(PracticeContext)
  let content = replaceContent(
    element.content[activeLang],
    activeLang,
    patient,
    practice,
  )
  return <div dangerouslySetInnerHTML={{ __html: content } as any} />
}

interface ParagraphProps {
  element: ParagraphType
  activeLang: string
}
const Paragraph = ({ activeLang, element }: ParagraphProps) => {
  const patient = getPatient(useFormikContext().values)
  const practice = useContext(PracticeContext)
  let content = replaceContent(
    element.content[activeLang],
    activeLang,
    patient,
    practice,
  )
  return <p dangerouslySetInnerHTML={{ __html: content } as any} />
}

interface HrProps {
  line?: string
}

export const StyledHr = styled.hr<HrProps>`
  margin: 50px 0;
  background-color: ${colors.grey};
  width: ${({ line }) => (line === 'short' ? '50%' : '100%')};
  margin-left: ${({ line }) => (line === 'short' ? 'auto' : 'initial')};
  margin-right: ${({ line }) => (line === 'short' ? 'auto' : 'initial')};
`
const StyledDots = styled.div`
  margin: 50px auto;
  text-align: center;

  &:before {
    color: ${colors.grey};
    content: '• • •';
    font-family: serif;
    font-size: 20px;
    letter-spacing: 1.5em;
    padding-left: 2em;
  }
`

interface SeparatorProps {
  element: SeparatorType
}
const Separator = ({ element }: SeparatorProps) =>
  element.style && element.style.line === 'dots' ? (
    <StyledDots />
  ) : (
    <StyledHr {...element.style} />
  )

const DivWithSpace = styled.div<CSSProps>`
  width: 100%;
  height: 0;
  padding-bottom: ${({ size }) =>
    size === 'l' ? '12%' : size === 'm' ? '6%' : '3%'};
`

interface SpacerProps {
  element: SpacerType
}
export const Spacer = ({ element }: SpacerProps) => (
  <DivWithSpace {...element.style} />
)

const ImgWrap = styled.div<CSSProps>`
  text-align: ${({ align }) => align};
  width: ${({ size }) =>
    size === 's' ? '33%' : size === 'm' ? '50%' : '100%'};
`

const Img = styled.img`
  max-width: 100%;
`

interface ImageProps {
  element: ImageType
}

const Image = ({ element }: ImageProps) => (
  <ImgWrap {...element.style}>
    {element.source === '{{praxis_logo}}' ? (
      <PracticeLogo originalSize desktopOnly />
    ) : (
      //<Img alt={element.source} src={element.source} />
      //@ts-ignore
      <DrawableImageInForm id={element.id} elsrc={element.source} />
    )}
  </ImgWrap>
)


export const InputContainer = styled.div`
  padding: 5px 0 4%;
`

export const InputField = styled(TextField)`
  height: 70px;

  input {
    color: ${colors.blue};
    font-size: 20px;
    border: 1px solid ${colors.blue};
    border-radius: 5px;
    padding: 12px 30px 12px 8px;

    &::placeholder {
      color: ${colors.black};
    }

    &:disabled {
      color: ${colors.grey};
      border: 1px solid ${colors.lightGrey};
      background-color: ${colors.lightGrey};
    }
  }
`

const FormErrorText = (props: any) => {
  return <FormHelperText error>{props.children}</FormHelperText>
}

const renderInputLabel = (element: SupportedATNInputType, activeLang: string) =>
  element.label &&
  element.label[activeLang] && (
    <Label>
      {element.label[activeLang]} {element.required && <RequiredStar />}
    </Label>
  )

const validateInput = (
  value: string | string[],
  element: SupportedATNInputType,
  activeLang: LanguageType,
) => {
  let error
  let message

  if (element.errorMessage) {
    if (element.errorMessage[activeLang]) {
      message = element.errorMessage[activeLang]
    } else {
      if (element.type === 'textInput' || element.type === 'textAreaInput') {
        message = (
          <FormattedMessage
            key={`generic-text-input-error-${activeLang}`}
            id="error.requiredTextInput"
          />
        )
      } else {
        message = (
          <FormattedMessage
            key={`generic-select-input-error-${activeLang}`}
            id="error.requiredSelectInput"
          />
        )
      }
    }
  }

  if (element.required && (!value || value.length === 0)) {
    error = message
  }
  return error
}

interface TextInputProps {
  element: TextInputType
  activeLang: string
}
const TextInput = ({ activeLang, element }: TextInputProps) => (
  <InputContainer id={element.id}>
    {renderInputLabel(element, activeLang)}
    <FastField
      key={`${element.id}-${activeLang}`}
      name={element.id}
      validate={(value: string) => validateInput(value, element, activeLang)}>
      {({ field, meta }: FieldProps) => (
        <InputField
          type="text"
          name={element.id}
          variant="outlined"
          placeholder={element.placeholder && element.placeholder[activeLang]}
          error={meta.touched && meta.error ? true : false}
          helperText={(meta.touched && meta.error) || ''}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value || ''}
          autoComplete="off"
          autoSave="off"
          autoCorrect="off"
          style={{
            width:
              element.style && element.style.size === 's'
                ? '33%'
                : element.style && element.style.size === 'm'
                ? '50%'
                : '100%',
          }}
        />
      )}
    </FastField>
  </InputContainer>
)

interface TextAreaInputProps {
  noBorder?: boolean
  size?: string
  error?: string
}
const TextAreaInput = styled(TextareaAutosize)<TextAreaInputProps>`
  color: ${colors.blue};
  outline-width: 0;
  border-radius: 5px;
  border: ${({ noBorder, error }) =>
    noBorder
      ? 'none'
      : error
      ? `1px solid ${colors.red}`
      : `1px solid ${colors.blue}`};
  width: ${({ size }) =>
    size === 'l' ? '100%' : size === 'm' ? '50%' : '33%'};
  padding: 8px 10px;
  font-size: 20px;

  &::placeholder {
    color: ${colors.grey};
  }
`

interface TextAreaProps {
  element: TextareaType
  activeLang: string
}
const TextArea = ({ activeLang, element }: TextAreaProps) => (
  <InputContainer id={element.id}>
    {renderInputLabel(element, activeLang)}
    <FastField
      key={`${element.id}-${activeLang}`}
      name={element.id}
      validate={(value: string) => validateInput(value, element, activeLang)}>
      {({ field, meta }: FieldProps) => (
        <TextAreaInput
          placeholder={element.placeholder && element.placeholder[activeLang]}
          error={meta.touched && meta.error ? 'error' : ''}
          minRows={4}
          maxRows={4}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={element.id}
          value={field.value || ''}
          autoComplete="off"
          autoSave="off"
          autoCorrect="off"
          {...element.style}
        />
      )}
    </FastField>
    <ErrorMessage name={element.id} component={FormErrorText} />
  </InputContainer>
)

const Options = styled.div<{ arrangement: ArrangementType }>`
  display: flex;
  flex-direction: ${({ arrangement }) =>
    arrangement === 'horizontal' ? 'row' : 'column'};
  align-items: ${({ arrangement }) =>
    arrangement === 'horizontal' ? 'center' : 'flex-start'};
  justify-content: flex-start;
`

const Children = styled.div`
  padding-left: 20px;
  border-left: 8px solid ${colors.lightGrey};
`

interface SelectOneProps {
  element: SelectOneType | YesNoType
  activeLang: string
  templateId: string
}

const SelectOne = (props: SelectOneProps) => {
  const { activeLang, element, templateId } = props
  const { values }: FormikContextType<any> = useFormikContext()
  const { setValues, setFieldValue } = useFormikContext()

  const handleChange = (event: any) => {
    const templateIdInt = parseInt(templateId)
    const optionValues = getValuesForOptionIds(
      element.options,
      event.target.value,
    )
    setValues({
      ...values,
      document_templates: [
        ...values.document_templates.slice(0, templateIdInt),
        { ...values.document_templates[templateIdInt], ...optionValues },
        ...values.document_templates.slice(
          templateIdInt + 1,
          values.document_templates.length,
        ),
      ],
    })
    setFieldValue(event.target.name, event.target.value)
  }

  return (
    <Field
      key={`${element.id}-${activeLang}`}
      name={element.id}
      validate={(value: string) => validateInput(value, element, activeLang)}>
      {({ field, form }: FieldProps) => {
        return (
          <InputContainer id={element.id}>
            {renderInputLabel(element, activeLang)}
            <RadioGroup
              aria-label={(element.label && element.label[activeLang]) || ''}
              name={element.id}
              value={field.value || ''}
              onChange={handleChange}
              onBlur={field.onBlur}>
              <Options
                arrangement={
                  (element.style && element.style.optionsArrangement) ||
                  'horizontal'
                }>
                {element.options.map((option: RadioButtonType) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={<Radio color="primary" />}
                    label={(option.label && option.label[activeLang]) || ''}
                  />
                ))}
              </Options>
            </RadioGroup>
            {element.options.map(
              (option: RadioButtonType) =>
                field.value === option.id && (
                  <Children key={option.id}>
                    {option.children.map((child: ATNValueType) => (
                      <FormElement
                        activeLang={activeLang}
                        element={child}
                        templateId={templateId}
                        key={child.id}
                      />
                    ))}
                  </Children>
                ),
            )}
            <ErrorMessage name={element.id} component={FormErrorText} />
          </InputContainer>
        )
      }}
    </Field>
  )
}

interface SelectMultipleProps {
  element: SelectMultipleType
  activeLang: string
  templateId: string
}

export const SelectMultiple = (props: SelectMultipleProps) => {
  const { activeLang, element, templateId } = props
  const elementPath = getElementPath(element.id)
  const { values }: FormikContextType<any> = useFormikContext()
  const { setValues, setFieldValue } = useFormikContext()
  const [field] = useField(element.id)

  const handleChange = (event: any) => {
    const templateIdInt = parseInt(templateId)
    if (element.id.includes('consents')) {
      setFieldValue(element.id, field.value.length === 0 ? ['yes'] : [])
      return
    }

    let tickedBoxesIds: string[]
    if (!field.value.includes(event.target.value)) {
      tickedBoxesIds = [...field.value, event.target.value]
    } else {
      tickedBoxesIds = field.value.filter(
        (id: any) => id !== event.target.value,
      )
    }

    const optionValues = getValuesForOptionIds(element.options, tickedBoxesIds)

    setValues({
      ...values,
      document_templates: [
        ...values.document_templates.slice(0, templateIdInt),
        { ...values.document_templates[templateIdInt], ...optionValues },
        ...values.document_templates.slice(
          templateIdInt + 1,
          values.document_templates.length,
        ),
      ],
    })
    setFieldValue(element.id, tickedBoxesIds)
  }

  return (
    <InputContainer id={element.id}>
      {renderInputLabel(element, activeLang)}
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <FormGroup>
          {element.options &&
            element.options.map((option: CheckboxType, i: number) => {
              return (
                <div key={option.id}>
                  <Field
                    key={`${element.id}-${activeLang}`}
                    type="checkbox"
                    validate={(value: string) =>
                      validateInput(value, element, activeLang)
                    }
                    name={element.id}
                    value={option.id}>
                    {({ field, form }: FieldProps) => (
                      <>
                        <FormControlLabel
                          onChange={handleChange}
                          checked={field.checked}
                          control={
                            <Checkbox color={'primary'} value={option.id} />
                          }
                          label={option.label && option.label[activeLang]}
                        />
                        {getNestedElement(form.values, elementPath) &&
                          getNestedElement(form.values, elementPath).includes(
                            option.id,
                          ) && (
                            <FormContent
                              activeLang={activeLang}
                              atn={option.children}
                              templateId={templateId}
                              key={`${option.id}-children`}
                            />
                          )}
                      </>
                    )}
                  </Field>
                </div>
              )
            })}
        </FormGroup>
      </FormControl>
      <ErrorMessage name={element.id} component={FormErrorText} />
    </InputContainer>
  )
}

const Sign = styled.div`
  width: 300px;
  margin: 35px 0;
  padding-bottom: 10px;
`
const SignField = styled.div`
  cursor: pointer;
  border: 4px dashed ${colors.red};
  padding: 20px;
  text-align: left;
`
const SignatureCaption = styled('p')`
  font-style: italic;
  font-size: 18px;
  margin: 10px 20px;
  color: ${colors.red};
`
const SignTitle = styled('p')`
  font-size: 20px;
  margin-bottom: 10px;
  color: ${colors.red};
`
const SignInfoLabel = styled('p')`
  margin-top: 10px;
  font-size: 16px;
  color: ${colors.red};
`

interface SignatureProps {
  element: SignatureType
  activeLang: string
}
const Signature = ({ activeLang, element }: SignatureProps) => (
  <Sign>
    <SignField>
      <SignTitle>Unterschrift</SignTitle>
      <SignInfoLabel>
        Unterschriften werden in Anamnese@Home leider nicht unterstützt.
      </SignInfoLabel>
    </SignField>
    <SignatureCaption>{element.caption[activeLang]}</SignatureCaption>
  </Sign>
)

const VideoWrap = styled.div<CSSProps>`
  text-align: ${({ align }) => align};
`
interface VideoProps {
  element: VideoType
}
const Video = ({ element }: VideoProps) => (
  <VideoWrap {...element.style}>
    <video
      controls={true}
      poster={element.placeholderSource}
      src={element.source}
      style={{ maxWidth: `100%` }}
    />
  </VideoWrap>
)

type PropsType = {
  activeLang: string
  element: ATNValueType
  templateId: string
}

const dsWinDates = ['geburtsdatum', 'versicherter_geburtsdatum']
interface CameraImageProps {
  element: CameraImageType
}
const ShowCam =  ({ element }: CameraImageProps) => {

	const { setShowCameraFromForm, CameraimgSource } = useContext(AppContext);
  const { setValues, setFieldValue } = useFormikContext()
  const handleshowCamera = () => {
    document.body.classList.add('removeScroll');

    setShowCameraFromForm(true);
	};

  React.useEffect(() => {
    setFieldValue(element.id, CameraimgSource)

  }, [CameraimgSource])
return(
    <>
    <InputContainer id={element.id}>
    <FastField
    key={`${element.id}`}
     name={element.id}>
      {({ field, meta }: FieldProps) => (
        <InputField
          type="text"
          name={element.id}
          variant="outlined"
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={CameraimgSource}
          autoComplete="off"
          autoSave="off"
          autoCorrect="off"
          style={{
            display:'none'
          }}
        />
      )}
    </FastField>
  </InputContainer>
    <Button variant="contained" color="primary" id="Save" onClick={handleshowCamera}>Bild aufnehmen...</Button>
  {CameraimgSource !== "" ?<img src={`${ImageServer}${CameraimgSource}`} alt={CameraimgSource} style={{width:"100%",marginTop:'10px'}} /> : null}
  {/* src={`${ImageServer}${CameraimgSource}`} */}
    </>
)
        }
        // interface CameraImageProps {
        //   element: CameraImageType
        // }
        const SignPad =  ({ activeLang, element }: SignatureProps) => {
          const { signpadsPath ,padId} = useContext(AppContext);
          const { setValues, setFieldValue } = useFormikContext()
      
          React.useEffect(() => {
            let objson = signpadsPath;
             setFieldValue(padId, objson)
        
          }, [signpadsPath])

        return(
            <>
            <InputContainer id={padId} style={{display:"none"}}>
            <FastField
            key={`${padId}`}
             name={padId}>
              {({ field, meta }: FieldProps) => (
                <InputField
                  type="text"
                  name={padId}
                  variant="outlined"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={signpadsPath}
                  autoComplete="off"
                  autoSave="off"
                  autoCorrect="off"
                  style={{
                    display:'none'
                  }}
                />
              )}
            </FastField>
          </InputContainer>
          <SavedSignature data={element} language={activeLang}/>
            </>
        )
          }



export class FormElement extends PureComponent<PropsType> {
  render() {
    
    const { activeLang, templateId } = this.props
    const elementId = assembleElementFormId(templateId, this.props.element.id)
    let element = { ...this.props.element, id: elementId }
    switch (element.type) {
      case 'heading':
        return <Heading activeLang={activeLang} element={element as HeadingType} />
      case 'group':
        return (
          <Group
            activeLang={activeLang}
            element={element as GroupType}
            templateId={templateId}
          />
        )
      case 'list':
        return <List activeLang={activeLang} element={element as ListType} />
      case 'text':
        return <Text activeLang={activeLang} element={element as TextType} />
      case 'paragraph':
        return <Paragraph activeLang={activeLang} element={element as ParagraphType} />
      case 'separator':
        return <Separator element={element as SeparatorType} />
      case 'spacer':
        return <Spacer element={element as SpacerType} />
      case 'image':
        return <Image element={element as ImageType} />
      case 'video':
        return <Video element={element as VideoType} />
      case 'textInput':
        const { dsWinFieldName, id, label, required } = element as TextInputType
        if (dsWinFieldName && dsWinDates.includes(dsWinFieldName)) {
          return <DatePicker name={id} label={label} required={required} />
        }
        return <TextInput activeLang={activeLang} element={element as TextInputType} />
      case 'textAreaInput':
        return <TextArea activeLang={activeLang} element={element as TextareaType} />
      case 'yesNo':
        return (
          <SelectOne
            activeLang={activeLang}
            templateId={templateId}
            element={{
              ...element as SelectOneType,
              style: { ...element.style, optionsArrangement: 'horizontal' },
            }}
          />
        )
      case 'signature':
         return  <SignPad activeLang={activeLang} element={element as SignatureType} />
        // return <Signature activeLang={activeLang} element={element as SignatureType} />
      case 'selectOne':
        return (
          <SelectOne
            activeLang={activeLang}
            element={element as SelectOneType}
            templateId={templateId}
          />
        )
      case 'selectMultiple':
        return (
          <SelectMultiple
            activeLang={activeLang}
            element={element as SelectMultipleType}
            templateId={templateId}
          />
        )
        case 'cameraInput':
          return <ShowCam element={element as CameraImageType}/>
      default:
        return (
          <p style={{ backgroundColor: 'red' }}>Not a match {element.type}</p>
        )
    }
  }
}
