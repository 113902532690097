import React from 'react'
import { FormElement } from './FormElement'
import { ATNValueType, ATNType } from '../types/ATNTypes'

interface FormContentProps {
  atn: ATNType
  templateId: string
  activeLang: string
}

export const FormContent = ({
  atn,
  templateId,
  activeLang,
}: FormContentProps) => {
  return (
    <>
      {atn.map((element: ATNValueType) => (
        <FormElement
          activeLang={activeLang}
          element={element}
          templateId={templateId}
          key={element.id}
        />
      ))}
    </>
  )
}
