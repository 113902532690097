import styled from 'styled-components'
import React from 'react'
import { Link } from '@material-ui/core'
import { athenaUrl } from '../Config'

const Image = styled.img<{ originalSize?: boolean }>`
  ${({ originalSize }) => (originalSize ? '' : `height: 60px;`)}
`

interface DampsoftLogoProps {
  originalSize?: boolean
}

export const DampsoftLogo = (props: DampsoftLogoProps) => {
  return (
    <Link href={athenaUrl} target="_blank">
      <Image alt="dampsoft-logo" src="logo.webp" {...props} />
    </Link>
  )
}
