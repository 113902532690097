// import React from 'react';
import React, { useEffect , useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AppContext } from '../Context/AppContext'
import { useParams, useNavigate   } from "react-router-dom";
import SettingsIcon from '@material-ui/icons/Settings';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor:"transparent",
    color:"white"
  },
 
  between:{
    justifyContent:"space-between"
  }
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  let navigate = useNavigate();

  const {setIsAuthorizeUser} = useContext(AppContext);
  const logout = () => {
    
    localStorage.clear();
    setIsAuthorizeUser(false)
    navigate("/login")
  };
  const Backbtn = () => {
    if(!!props.backurl){

      navigate(props.backurl)
      
    } else{
      
      navigate(-1)
    }

  }
  const gotoPatient = () => {
    navigate("/newDocumentType")
    // navigate("/SelectPatient")
  }
  const gotoSettings = () => {
    navigate("/Settings")
  }

  const LeftButton = (value) => {
    switch (value) {
      case "Back":
        return <Button color="default" onClick={Backbtn} className={classes.menuButton} startIcon={<ArrowBackIosIcon />} size="small"> {props.backbtnTitle} </Button>
      case "Logout":
      return <Button color="default" onClick={logout} className={classes.menuButton} startIcon={<ArrowBackIosIcon />} size="small"> Ausloggen </Button>

      default:
        break;
    }
  }
  const RightButton = (value) => {
    switch (value) {
      case "Create Patient":
        return <Button color="default" onClick={gotoPatient} className={classes.menuButton} size="small"> Create Patient </Button>
      case "setting":
      return <Button color="default" onClick={gotoSettings} className={classes.menuButton} endIcon={<SettingsIcon />} size="small"> Einstellungen </Button>
      case "SelectMulti":
      return <Button color="default" onClick={logout} className={classes.menuButton}  size="small"> Mehrere auswahlen      </Button>

      default:
        break;
    }
  }


  return (
    <div className={classes.root}>
      <AppBar position="static" >
        <Toolbar className={classes.between}>
          <div>
         {
           LeftButton(props.leftButton)
         }
         </div>
          <Typography variant="body2" className={classes.title}>
           {props.title}
          </Typography>
          <div>
          {
            RightButton(props.rightButton)
          }
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
