import React ,{useContext , useState , useRef,useEffect} from "react"
import { render } from "@testing-library/react"
import styled from "styled-components"
import { AppContext } from '../../Context/AppContext'
import DrawOnImage from "./DrawOnCameraCaptureimg"
import { NavLink  } from "react-router-dom";
import Header from "../../components/header"

const ListRow = styled(NavLink)`
    padding:1.5rem;
    font-size:20px;
    font-weight:500;
    display:block;
    border-bottom: 1px solid #ccc;
    text-decoration:none;
    color:#000;
    &:hover {
        background-color: #f8f8f8;
        cursor:pointer !important;
    }
    &:last-child {
        border-bottom: none
    }
    `
const Ipadgalbutton = styled.div`
    padding:1.5rem;
    font-size:20px;
    font-weight:500;
    display:block;
    border-bottom: 1px solid #ccc;
    text-decoration:none;
    color:#000;
    &:hover {
        background-color: #f8f8f8;
        cursor:pointer !important;
    }
    &:last-child {
        border-bottom: none
    }
    `
const IpadGallery = styled.input`
    padding:1.5rem;
    font-size:20px;
    
    font-weight:500;
    display:none;
    border-bottom: 1px solid #ccc;
    text-decoration:none;
    color:#000;
    &:hover {
        background-color: #f8f8f8;
        cursor:pointer !important;
    }
    &:last-child {
        border-bottom: none
    }
    `

function MediaList(){

    const {TriggerCameraEvent} = useContext(AppContext);
    const [galleryimg, setgalleryimg] = useState("");
     const inputimg = useRef(null);

    const Triggercam = () => {
        TriggerCameraEvent(true)
    }
    const GalleryImg = () => {
        inputimg.current.click();
    }
    const Selectimage = (e) => {
        
        setgalleryimg(e.target.value)
    }

    useEffect(() => {
        console.log(galleryimg)
    }, [galleryimg]);
    return(
        <>
         <Header backbtnTitle="Zuruck" leftButton="Back" title="Medien" rightButton="" />

            {/* <DrawOnImage /> */}
                <ListRow to="/illustration">
                Illustrationen 
                </ListRow>
        
            <ListRow to="/dsView">
            DICOM-Importe 
            </ListRow>
            
                <ListRow to="/dsView">
                DS-View 
                </ListRow>
        
            <ListRow to="/dsView">
            Dokumente vom Athena-Drucker 
            </ListRow >
        
            <Ipadgalbutton onClick={GalleryImg}>
            iPad-Fotogalerie 
            </Ipadgalbutton>
            <IpadGallery type="file" ref={inputimg} onChange={(e) => Selectimage(e)} accept="image/*" />

         
        
            <ListRow onClick={Triggercam} to="#">
                Kamera 
            </ListRow>
        
            
        </>
    )
}
export default MediaList