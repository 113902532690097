import React from 'react'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { Spacer } from './FormElement'

export const FormSpacer = () => (
  <Spacer
    element={{
      id: 'patientInfo-spacer',
      type: 'spacer',
      style: { size: 's' },
    }}
  />
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

interface CardHeaderProps {
  title: JSX.Element
}

export const CardHeader = (props: CardHeaderProps) => (
  <>
    <Grid item>
      <Wrapper>
        <Typography variant="h1">{props.title}</Typography>
      </Wrapper>
    </Grid>
    <FormSpacer />
  </>
)
