import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { DocumentFlowContext } from '../Context/DocumentFlowContext'

export const FormFocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext()
  const { stepIndex } = useContext(DocumentFlowContext)
  const templateId = stepIndex - 1

  // TODO React memo? re-render less often
  React.useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors)
      if (keys.length > 0) {
        const key = keys[0]
        const firstError = (errors as any)[key]
        if (firstError && firstError.length > 0 && firstError[templateId]) {
          const selector = `[name='${key}.${templateId}.${
            Object.keys(firstError[templateId])[0]
          }']`
          const errorElement = document.querySelector(selector) as HTMLElement
          if (errorElement) {
            errorElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          }
        }
      }
    }
  }, [errors, isSubmitting, isValidating, templateId])

  return null
}
