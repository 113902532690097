import React from "react";
import { fabric } from "fabric";
import Button from '@material-ui/core/Button';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RemoveIcon from '@material-ui/icons/Remove';
import Slider, { Range } from 'rc-slider';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Grid from '@material-ui/core/Grid';
import 'rc-slider/assets/index.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./zoom.css"
import Konvacanvas from "./konvacanvas";
import $ from "jquery";

const canvasShadow = {
  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
}
const btnmr = {
  marginRight: "5px"
}
const canvasContainer = {
  maxWidth: "860px",
  maxHeight: "400px",
  overflow: "hidden",
  margin: "20px auto",
  boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
  position: "relative",
  display: "none"


}
const zoomStyling = {
  height: "100px",
  position: "absolute",
  top: "50%",
  right: "20px",
  transform: "translate(0px,-50%)",
  zIndex: 9
}


fabric.Object.prototype.setOriginToCenter = function () {
  this._originalOriginX = this.originX;
  this._originalOriginY = this.originY;

  var center = this.getCenterPoint();

  this.set({
    originX: 'center',
    originY: 'center',
    left: center.x,
    top: center.y
  });
};

fabric.Object.prototype.setCenterToOrigin = function () {
  var originPoint = this.translateToOriginPoint(
    this.getCenterPoint(),
    this._originalOriginX,
    this._originalOriginY);

  this.set({
    originX: this._originalOriginX,
    originY: this._originalOriginY,
    left: originPoint.x,
    top: originPoint.y
  });
};
// fabric.Canvas.prototype.EraseMode = function (eraseMode) {
//   let lastX;
//   let lastY;
//   let moving = false;

//   let renderingElement = $(".upper-canvas ");
//   let drawingElement = $(".lower-canvas");
//   let drawingCtx =$(drawingElement).getContext('2d');
//   let renderingCtx =$(renderingElement).getContext('2d');
//   let brushColor = "rgba(0,0,0,0)";
//   let brushSize = 5;
// if(eraseMode){

//   this.on('mouse:down', (ev) => {
//     moving = true;
//     lastX = ev.pageX - renderingElement.offsetLeft;
//     lastY = ev.pageY - renderingElement.offsetTop;
//   });

//   this.on('mouse:up', (ev) => {
//     moving = false;
//     lastX = ev.pageX - renderingElement.offsetLeft;
//     lastY = ev.pageY - renderingElement.offsetTop;
//   });

//   this.on('mouse:move', (ev) => {


//     if (moving) {
//       if (this.__isDrawingMode === true) {
//         drawingCtx.globalCompositeOperation = "source-over";
//       } else {
//         drawingCtx.globalCompositeOperation = "destination-out";
//       }
//       let currentX = ev.pageX - renderingElement.offsetLeft;
//       let currentY = ev.pageY - renderingElement.offsetTop;

//       drawingCtx.beginPath();
//       drawingCtx.lineJoin = "round";
//       drawingCtx.moveTo(lastX, lastY);
//       drawingCtx.lineTo(currentX, currentY);
//       drawingCtx.closePath();
//       drawingCtx.strokeStyle = brushColor;
//       drawingCtx.lineWidth = brushSize;
//       drawingCtx.stroke();

//       lastX = currentX;
//       lastY = currentY;

//       // draw to visible canvas
//       renderingCtx.clearRect(0, 0, renderingElement.width, renderingElement.height);
//       renderingCtx.globalCompositeOperation = 'source-atop';
//       renderingCtx.drawImage(drawingElement, 0, 0);

//       // reset
//       renderingCtx.globalCompositeOperation = 'source-over';
//     }
// });



// }}

const STATE_IDLE = 'idle';
const STATE_PANNING = 'panning';
fabric.Canvas.prototype.toggleDragMode = function (dragMode) {
  // Remember the previous X and Y coordinates for delta calculations
  let lastClientX;
  let lastClientY;

  let zoomStartScale;
  let pausePanning;

  // Keep track of the state
  let state = STATE_IDLE;
  // We're entering dragmode
  if (dragMode) {
    // Discard any active object
    this.discardActiveObject();
    // Set the cursor to 'move'
    this.defaultCursor = 'move';
    // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
    this.forEachObject(function (object) {
      object.prevEvented = object.evented;
      object.prevSelectable = object.selectable;
      object.evented = false;
      object.selectable = false;
    });
    // Remove selection ability on the canvas
    this.selection = false;
    // When MouseUp fires, we set the state to idle
    this.on('mouse:up', function (e) {
      state = STATE_IDLE;
    });
    // When MouseDown fires, we set the state to panning
    this.on('mouse:down', (e) => {
      state = STATE_PANNING;
      lastClientX = e.e.clientX;
      lastClientY = e.e.clientY;
    });
    // When the mouse moves, and we're panning (mouse down), we continue
    this.on('mouse:move', (e) => {
      if (state === STATE_PANNING && e && e.e) {
        // let delta = new fabric.Point(e.e.movementX, e.e.movementY); // No Safari support for movementX and movementY
        // For cross-browser compatibility, I had to manually keep track of the delta

        // Calculate deltas
        let deltaX = 0;
        let deltaY = 0;
        if (lastClientX) {
          deltaX = e.e.clientX - lastClientX;
        }
        if (lastClientY) {
          deltaY = e.e.clientY - lastClientY;
        }
        // Update the last X and Y values
        lastClientX = e.e.clientX;
        lastClientY = e.e.clientY;

        let delta = new fabric.Point(deltaX, deltaY);
        this.relativePan(delta);

        var canvasViewPort = this.viewportTransform;

        var imageHeight = this.height * canvasViewPort[0];
        var imageWidth = this.width * canvasViewPort[0];

        var bottomEndPoint = this.height * (canvasViewPort[0] - 1);
        if (canvasViewPort[5] >= 0 || -bottomEndPoint > canvasViewPort[5]) {
          canvasViewPort[5] = (canvasViewPort[5] >= 0) ? 0 : -bottomEndPoint;
        }

        var rightEndPoint = this.width * (canvasViewPort[0] - 1);
        if (canvasViewPort[4] >= 0 || -rightEndPoint > canvasViewPort[4]) {
          canvasViewPort[4] = (canvasViewPort[4] >= 0) ? 0 : -rightEndPoint;
        }



        // this.trigger('moved');
      }
    });

  } else {
    // When we exit dragmode, we restore the previous values on all objects
    this.forEachObject(function (object) {
      object.evented = (object.prevEvented !== undefined) ? object.prevEvented : object.evented;
      object.selectable = (object.prevSelectable !== undefined) ? object.prevSelectable : object.selectable;
    });
    // Reset the cursor
    this.defaultCursor = 'default';
    // Remove the event listeners
    this.off('mouse:up');
    this.off('mouse:down');
    this.off('mouse:move');
    // Restore selection ability on the canvas
    this.selection = true;
  }
};




export default class Drawonjaw extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: "",
      canvas: "",
      isDrawingMode: true,
      lineColor: "#00aaf2",
      lineHeight: 5,
      showColorMenu: false,
      zoomVal: 1,
      prezoom: 1,
      bgAngle: 0,
      ObjGroup: "",
      initialScaleOfImage: "",
      eraserMode: false,
      ZoomInCount: 1,
      zoominhide: null,
      scale: 1,
      imgwidth: 860,
      imgheight: 400,
      angle: 0,
      offsetx: 0,
      offsety: 0,
      layeroffx: 0,
      layeroffy: 0,
      scalex: 1,
      scaley: 1,
      rotatedeg: "0deg",
      tool: "pen",





    }

    this.disableMovebg = this.disableMovebg.bind(this);
    this.startErase = this.startErase.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.canvas = React.createRef();
    this.zoominhide = React.createRef();
    this.zoomouthide = React.createRef();
    this.resetzoomhere = React.createRef();
    this.konva = React.createRef();
    this.exportKonva = React.createRef();
  }

  componentDidMount() {
    let self = this;
    this.initCanvas(self);
    this.setState({
      zoominhide: this.zoominhide.current
    })


  }

  componentDidUpdate() {

    window.addEventListener('resize', this.updateDimensions);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    if (window.innerWidth <= 860) {
      this.setState({ imgwidth: window.innerWidth, imgheight: window.innerHeight });

    } else {
      this.setState({ imgwidth: 860, imgheight: 400 });
    }
  };




  initCanvas = (self) => {
    var canvas = new fabric.Canvas(this.canvas.current, {
      isDrawingMode: this.state.isDrawingMode,
      preserveObjectStacking: true,
      allowTouchScrolling: true,
      width: 860,
      height: 400,
      evented: false
    });
    canvas.setHeight(400);
    canvas.setWidth(860);



    var url = this.props.setimg;
    const imgss = fabric.Image.fromURL(url, function (oImg) {
      oImg.set({
        "left": 0,
        "top": 0,
        angle: 0,
        "scaleX": canvas.width / oImg.width,
        "scaleY": canvas.height / oImg.height,
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
      })
      let scale = canvas.width / oImg.width;
      self.setState({
        initialScaleOfImage: scale
      })
      canvas.add(oImg);
      canvas.renderAll();
    });
    canvas.on("mouse:down", function (e) {
      if (self.state.isDrawingMode === false) {
        return
      }
    })

    canvas.on("mouse:move", function (e) {

      if (self.state.isDrawingMode === true) {
        // if(e.target === null){
        //    self.disableDrawing()
        //    this.__eventListeners = {};
        //   return
        //  }else{

        //    self.DrawToggle()


        //  }
      }
    })


    canvas.on('object:moving', function (e) {
      var obj = e.target;
      // if object is too big ignore
      if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
        return;
      }
      obj.setCoords();
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }
      // bot-right corner
      if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width) {
        obj.top = Math.min(obj.top, obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
        obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
      }
    });



    canvas.renderAll();
    // canvas.toggleDragMode(true);

    this.setState({
      canvas: canvas
    })

  }


  resetBtn = () => {
    // var canvas = this.state.canvas;
    // this.resetzoomhere.current.click();

    // canvas.clear();
    // var url = this.props.setimg;
    // fabric.Image.fromURL(url, function (oImg) {
    //   oImg.set({
    //     "left": 0,
    //     "top": 0,
    //     angle: 0,
    //     "scaleX": canvas.width / oImg.width,
    //     "scaleY": canvas.height / oImg.height,
    //     lockMovementX: true,
    //     lockMovementY: true,
    //     selectable: false
    //   })
    //   canvas.add(oImg);
    //   canvas.renderAll();
    // });
    let child = this.exportKonva.current.getLayers()[1].children = [];
    // this.exportKonva.current.getLayers()[0].children[0].setWidth(860)


    // this.exportKonva.current.getStage().setRotation(0);
    // this.exportKonva.current.getStage().setWidth(860);
    // this.exportKonva.current.getStage().setOffsetX(0);
    // this.exportKonva.current.getStage().setOffsetY(0);
    this.exportKonva.current.getLayers()[1].draw();
    this.setState({
      angle: 0,
      imgwidth: 860,
      rotatedeg: "0deg",
      offsetx: 0,
      offsety: 0,
      layeroffx: 0,
      layeroffy: 0,
      scalex: 1,
      scaley: 1
    })
    this.resetZoom();

  }
  DrawToggle = () => {

    // this.disableMovebg();
    var canvas = this.state.canvas;
    canvas.isDrawingMode = true;
    var brush = canvas.freeDrawingBrush;
    brush.color = this.state.lineColor;
    brush.limitedToCanvasSize = true;
    this.setState({
      isDrawingMode: true,
      eraserMode: false,
      tool: "pen"
    })

  }
  removeCanvasEvents = () => {
    var canvas = this.state.canvas;
    canvas.off('mouse:down');
    canvas.off('mouse:move');
    canvas.off('mouse:up');
    canvas.off('object:moving');
  }
  disableDrawing = () => {
    var canvas = this.state.canvas;
    canvas.isDrawingMode = false;
    canvas.getObjects()[0].selectable = false;

    this.setState({
      isDrawingMode: false
    })



    // canvas.toggleDragMode(true);

  }
  disableMovebg = () => {
    var canvas = this.state.canvas;
    canvas.getObjects()[0].selectable = false;
    this.setState({
      canvas: canvas
    })
    // canvas.toggleDragMode(false);

  }
  ChangeColor = (e) => {
    if (this.state.isDrawingMode === true) {
      var canvas = this.state.canvas;
      var brush = canvas.freeDrawingBrush;
      brush.color = e.target.attributes.value.value;
      if (brush.getPatternSrc) {
        brush.source = brush.getPatternSrc.call(brush);
      }
      this.setState({
        lineColor: e.target.attributes.value.value
      })
    }

  };

  ChangeLineWidth = (e) => {
    var canvas = this.state.canvas;
    if (this.state.isDrawingMode === true) {
      canvas.freeDrawingBrush.width = parseInt(e.target.attributes.value.value, 10) || 1;
      var he = parseInt(e.target.attributes.value.value);
      this.setState({
        lineHeight: he
      })
    };
  }

  moveBg = () => {
    this.disableDrawing()
    var canvas = this.state.canvas;
    canvas.getObjects()[0].selectable = false;
    this.groupAll();
    this.setState({
      canvas: canvas
    })
  }
  rotateleft = () => {

    let angle = this.state.angle - 90;
    angle = angle > 360 ? 90 : angle < 0 ? 270 : angle;
    let lastcanvs = $(this.konva.current).find("canvas")

    switch (angle) {
      case 180:
        // $(lastcanvs[1]).css("transform","rotate(180deg) ")
        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "180deg",
          offsetx: 860,
          offsety: 400,
          layeroffx: 860,
          layeroffy: 400,
          scalex: 1,
          scaley: 1
        })

        break;
      case 360:
        // $(lastcanvs[1]).css("transform","rotate(360deg) ")

        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "360deg",
          offsetx: 0,
          offsety: 0,
          layeroffx: 0,
          layeroffy: 0,
          scalex: 1,
          scaley: 1
        })

        break;

      case 0:
        // $(lastcanvs[1]).css("transform","rotate(0deg) ")

        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "0deg",
          offsetx: 0,
          offsety: 0,
          layeroffx: 0,
          layeroffy: 0,
          scalex: 1,
          scaley: 1
        })

        break;
      case 270:
        // $(lastcanvs[1]).css("transform","rotate(90deg) ")

        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "270deg",
          offsetx: 860,
          offsety: -400,
          layeroffx: 0,
          layeroffy: 400,
          scalex: .47,
          scaley: .6
        })
        this.exportKonva.current.getStage().draw();
        break;
      case 90:
        // $(lastcanvs[1]).css("transform","rotate(270deg) ")

        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "90deg",
          offsetx: 0,
          offsety: 1000,
          layeroffx: 400,
          layeroffy: 0,
          scalex: .47,
          scaley: .6
        })
        this.exportKonva.current.getStage().draw();
        break;

      default:
        break;
    }
    this.exportKonva.current.getStage().draw();
  }
  rotateright = () => {

    // this.rotateObject(90);

    let angle = this.state.angle + 90;
    angle = angle > 360 ? 90 : angle < 0 ? 270 : angle;
    let lastcanvs = $(this.konva.current).find("canvas")


    switch (angle) {
      case 180:
        // $(lastcanvs[1]).css("transform","rotate(180deg) ")

        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "180deg",
          offsetx: 860,
          offsety: 400,
          layeroffx: 860,
          layeroffy: 400,
          scalex: 1,
          scaley: 1
        })
        this.exportKonva.current.getStage().draw();
        break;
      case 360:
        // $(lastcanvs[1]).css("transform","rotate(360deg) ")


        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "360deg",
          offsetx: 0,
          offsety: 0,
          layeroffx: 0,
          layeroffy: 0,
          scalex: 1,
          scaley: 1
        })
        this.exportKonva.current.getStage().draw();
        break;
      case 270:
        // $(lastcanvs[1]).css("transform","rotate(90deg) ")

        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "270deg",
          offsetx: 860,
          offsety: -400,
          layeroffx: 0,
          layeroffy: 400,
          scalex: .47,
          scaley: .6
        })
        this.exportKonva.current.getStage().draw();
        break;
      case 90:
        // $(lastcanvs[1]).css("transform","rotate(270deg) ")

        this.setState({
          angle: angle,
          imgwidth: 860,
          rotatedeg: "90deg",
          offsetx: 0,
          offsety: 1000,
          layeroffx: 400,
          layeroffy: 0,
          scalex: .47,
          scaley: .6
        })
        this.exportKonva.current.getStage().draw();
        break;

      default:
        break;
    }


    this.exportKonva.current.getStage().draw();




  }

  rotateObject(angleOffset) {
    var canvas = this.state.canvas;
    this.groupAll();

    var obj = canvas.getActiveObject(),
      resetOrigin = false;

    if (!obj) return;

    var angle = obj.angle + angleOffset;

    if ((obj.originX !== 'center' || obj.originY !== 'center') && obj.centeredRotation) {
      obj.setOriginToCenter && obj.setOriginToCenter();
      resetOrigin = true;
    }


    var getangle = this.state.bgAngle + angleOffset;
    getangle = getangle > 360 ? 90 : getangle < 0 ? 270 : getangle;
    this.setState({
      bgAngle: getangle
    })
    console.log("check angle :" + getangle)


    angle = angle > 360 ? 90 : angle < 0 ? 270 : angle;

    // this.rotateWholeCanvas(angle)

    if (getangle === 90 || getangle === 270) {

      obj.set('scaleX', canvas.height / obj.width)
      obj.set("angle", angle).setCoords();
    } else {

      obj.set("angle", angle).setCoords();
      obj.set('scaleY', canvas.width / obj.height);
      // obj.set('scaleX', canvas.width / obj.height);

    }



    if (resetOrigin) {
      obj.setCenterToOrigin && obj.setCenterToOrigin();
    }
    canvas.renderAll();

    canvas.calcOffset()
    console.log(obj.angle);
  }

  rotateonsave(angleOffset) {
    var canvas = this.state.canvas;
    this.groupAll();

    var obj = canvas.getObjects()[0],
      resetOrigin = false;

    if (!obj) return;

    var angle = obj.angle + angleOffset;

    if ((obj.originX !== 'center' || obj.originY !== 'center') && obj.centeredRotation) {
      obj.setOriginToCenter && obj.setOriginToCenter();
      resetOrigin = true;
    }




    angle = angle > 360 ? 90 : angle < 0 ? 270 : angle;

    // this.rotateWholeCanvas(angle)


    switch (this.state.bgAngle) {
      case 180:
        obj.set("angle", angle).setCoords();
        obj.set('scaleX', canvas.width / obj.width);
        canvas.renderAll();

        break;
      case 360:
        obj.set("angle", angle).setCoords();
        obj.set('scaleX', canvas.width / obj.width);
        canvas.renderAll();

        break;
      case 270:
        obj.set("angle", angle).setCoords();
        canvas.renderAll();
        obj.set('scaleY', canvas.width / obj.width);

        canvas.renderAll();

        break;
      case 90:
        obj.set("angle", angle).setCoords();
        canvas.renderAll();
        obj.set('scaleY', canvas.width / obj.width);

        canvas.renderAll();

        break;

      default:
        break;
    }


    if (resetOrigin) {
      obj.setCenterToOrigin && obj.setCenterToOrigin();
    }
    canvas.renderAll();

    canvas.calcOffset()
    console.log(obj.angle);
  }



  groupAll = () => {

    var canvas = this.state.canvas;
    var objectList = canvas.getObjects();
    let group = new fabric.Group();

    objectList.forEach((o, i) => {

      if (i > -1) {

        group.addWithUpdate(o);
        canvas.remove(o);
      }
    });
    group.set({
      selectable: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
    })

    canvas.add(group);

    canvas.setActiveObject(group)
    this.setState({
      ObjGroup: group
    })
  }
  rotateWholeCanvas = (rads) => {

    var canvas = this.state.canvas;
    var canvasCenter = new fabric.Point(430, 200); // center of canvas
    canvas.getObjects().forEach(function (obj) {
      var objectOrigin = new fabric.Point(obj.left, obj.top);
      var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, rads);
      obj.top = new_loc.y;
      obj.left = new_loc.x;
      obj.angle += rads; //rotate each object buy the same angle
      canvas.renderAll();
      console.log(new_loc, rads);
    });

  }

  SaveImg = () => {
    this.setState({
      angle: 0,
      imgwidth: 860,
      rotatedeg: "0deg",
      offsetx: 0,
      offsety: 0,
      layeroffx: 0,
      layeroffy: 0,
      scalex: 1,
      scaley: 1
    })
    this.exportKonva.current.getStage().rotation(0)
    this.exportKonva.current.getStage().setOffsetX(0)
    this.exportKonva.current.getStage().setOffsetY(0)
    this.exportKonva.current.getStage().setScaleX(1)
    this.exportKonva.current.getStage().setScaleY(1)


    this.exportKonva.current.getStage().width(860)
    this.exportKonva.current.getStage().height(400)
    this.exportKonva.current.getStage().draw()

    // var canvas = this.state.canvas;
    // // this.resetZoom();

    // var canvas = this.state.canvas;
    // this.groupAll();

    // switch (this.state.bgAngle) {
    //   case 90:
    //     this.rotateonsave(270)

    //     break;
    //   case 180:
    //     this.rotateonsave(180)

    //     break;
    //   case 270:
    //     this.rotateonsave(90)

    //     break;
    //   case 360:
    //     this.rotateonsave(0)

    //     break;

    //   default:
    //     break;
    // }
    // // obj.set("scaleX" , canvas.width / obj.width)
    // // obj.set("scaleY" , canvas.height / obj.height)

    // canvas.renderAll();

    var img = this.exportKonva.current.toDataURL({
      format: "png"
    })
    console.log("imghere")
    console.log(img)

    this.props.getimg(img);

  }
  // Zoom In
  zoomIn(value) {
    var SCALE_FACTOR = value;
    var canvas = this.state.canvas;
    var zoomMax = 10;
    if (canvas.getZoom().toFixed(5) > zoomMax) {
      console.log("zoomIn: Error: cannot zoom-in anymore");
      return;
    }
    // canvas.setZoom(canvas.getZoom() * SCALE_FACTOR);
    canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), canvas.getZoom() * SCALE_FACTOR);

    canvas.setHeight(canvas.getHeight() * SCALE_FACTOR);
    canvas.setWidth(canvas.getWidth() * SCALE_FACTOR);
    canvas.renderAll();
  }

  // Zoom Out
  zoomOut(value) {
    var SCALE_FACTOR = value;
    var canvas = this.state.canvas;

    if (canvas.getZoom().toFixed(5) < 1) {
      console.log("zoomOut: Error: cannot zoom-out anymore");
      return;
    }
    // canvas.setZoom(canvas.getZoom() / SCALE_FACTOR);

    canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), canvas.getZoom() / SCALE_FACTOR);

    canvas.setHeight(canvas.getHeight() / SCALE_FACTOR);
    canvas.setWidth(canvas.getWidth() / SCALE_FACTOR);
    canvas.renderAll();
  }

  // Reset Zoom
  resetZoom() {
    var canvas = this.state.canvas;
    this.resetzoomhere.current.click();
    // canvas.setHeight(canvas.getHeight() / canvas.getZoom());
    // canvas.setWidth(canvas.getWidth() / canvas.getZoom());
    // canvas.setZoom(1);
    // canvas.setViewportTransform([1,0,0,1,0,0]); 

    // canvas.renderAll();
    // this.setState({
    //   zoomVal: 1,
    //   prezoom: 1
    // })


  }

  getsliderVal = (val) => {
    this.setState({ prezoom: val })
    console.log(this.state.prezoom)
    if (val > this.state.zoomVal) {

      // this.zoomIn(val);
      this.zoominhide.current.click();

    } else {
      // this.zoomOut(val);
      this.zoomouthide.current.click();

      if (val === 1) {
        this.resetZoom();
        this.resetzoomhere.current.click();
      }
    }
    this.setState({
      zoomVal: val
    })
    console.log(val)

  }

  startErase = (e) => {

    if (this.state.eraserMode === true) {
      this.setState({
        eraserMode: false,
        tool: "pen"
      })
      e.currentTarget.style.color = "#0000008a"

    } else {
      this.setState({
        eraserMode: true,
        tool: "eraser"
      })
      e.currentTarget.style.color = "red"

    }



    //   var canvas = this.state.canvas;

    //   //eraser
    //  var paths =  canvas.getObjects('path');
    // canvas.remove(paths[paths.length - 1])




    //   canvas.renderAll();


    // let canvas = this.state.canvas;
    // canvas.EraseMode(true);
  }
  zoomInBtn = () => {
    // let zoomCOunt = parseFloat(this.state.ZoomInCount);
    // let prezoom = parseFloat(this.state.prezoom);
    // let count = parseFloat( zoomCOunt + 0.1).toFixed(1) ;
    // count = parseFloat(count);

    // console.log(count)

    // if(count > 1 && count <=1.5){
    //   this.zoomIn(count);
    // this.setState({
    //   ZoomInCount : count,
    //   prezoom : count
    // })
    let count = this.state.prezoom + 1;
    this.zoominhide.current.click()
    console.log("zoomin btn " + count)
    this.setState({

      prezoom: count
    })




  }
  zoomOutBtn = () => {
    // let zoomCOunt = parseFloat(this.state.ZoomInCount);
    // let prezoom = parseFloat(this.state.prezoom);

    // let count = parseFloat( zoomCOunt - 0.1).toFixed(1) ;
    // count = parseFloat(count);

    // console.log(count)

    // if(count === 1 || count === 1.0){
    //   this.resetZoom();
    // }
    // if(count > 1 && count <=1.5){
    //   this.zoomOut(count);
    // this.setState({
    //   ZoomInCount : count,
    //   prezoom : count

    // })
    // }

    let count = this.state.prezoom - 1;
    this.zoomouthide.current.click()

    console.log("zoomout btn " + count)

    this.setState({

      prezoom: count
    })

  }


  saveBtn = () => {
    
    this.SaveImg();
    this.props.closepopup();
  }



  render() {
    let options = {
      disabled: this.state.isDrawingMode === true ? true : false,

    }
    let zoomIn = {
      step: 10
    }
    let zoomOut = {
      step: 10
    }

    return (
      <>
        <TransformWrapper setScale={3} options={options} zoomIn={zoomIn} zoomOut={zoomOut}>
          {({ zoomIn, zoomOut, scale, resetTransform, ...rest }) => (

            <div style={{ position: "relative", maxWidth: "860px", margin: "0 auto" }}>

              <TransformComponent style={{ margin: "0 auto" }}>
                <div style={canvasContainer}>
                  <canvas ref={this.canvas} id="canvas" width="860" height="400" style={canvasShadow}></canvas>

                </div>
                <div ref={this.konva} >
                  <Konvacanvas export={this.exportKonva} tool={this.state.tool} loffx={this.state.layeroffx} loffy={this.state.layeroffy} scalex={this.state.scalex} scaley={this.state.scaley} offx={this.state.offsetx} offy={this.state.offsety} isDrawingMode={this.state.isDrawingMode} lineHeight={this.state.lineHeight} linecolor={this.state.lineColor} imgwidth={this.state.imgwidth} imgheight={this.state.imgheight} angle={this.state.angle} imghere={this.props.setimg} />

                </div>
              </TransformComponent>
              <div style={zoomStyling}>

                <div style={{ display: "flex", flexDirection: "column", height: "120px", alignItems: "center" }}>


                  <span onClick={zoomIn} ref={this.zoominhide} style={{ cursor: "pointer" }}>
                  <ZoomInIcon  />

                  </span>
                
                  <Slider min={1} value={scale} max={8} vertical onChange={this.getsliderVal} />
                  <span onClick={zoomOut} ref={this.zoomouthide} style={{ marginTop: "10px", cursor: "pointer" }}>
                  <ZoomOutIcon  />
                    
                    </span>
                  <span onClick={resetTransform} ref={this.resetzoomhere}></span>
                </div>

              </div>
            </div>
          )}


        </TransformWrapper>
        <Grid container alignItems="center" spacing={0} style={{ maxWidth: "860px", margin: " 0 auto" }}>
          <Grid item md={3}>
            <div>
              <Button variant="contained" disabled={this.state.isDrawingMode === true ? true : false} style={btnmr} color="primary" onClick={this.DrawToggle} id="Draw" >Draw</Button>
              <Button variant="contained" disabled={this.state.isDrawingMode === false ? true : false} style={btnmr} color="primary" id="Move" onClick={this.moveBg} >Move</Button>

            </div>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={0}>
              <Grid item md={"auto"}>
                <div className="rotateBtns">
                  <Button variant="contained" color="primary" style={{ marginRight: "5px" }} id="Reset" onClick={this.resetBtn} >Reset</Button>
                  <IconButton color="default" aria-label="rotate left" component="span" onClick={this.rotateleft}>
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton color="default" aria-label="rotate right" component="span" onClick={this.rotateright}>
                    <RotateRightIcon />
                  </IconButton>
                </div>
              </Grid>
              <Grid item md={"auto"}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>Farbe:</label>
                  <LineColor changecolor={this.ChangeColor} linecolor={this.state.lineColor} />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label>Breite:</label>
                    <LineWidth changewidth={this.ChangeLineWidth} linecolor={this.state.lineColor} lineHeight={this.state.lineHeight} />
                  </div>
                  <IconButton color="default" aria-label="Eraser" onClick={this.startErase} component="span">
                    <i className="far fa-eraser"></i>
                  </IconButton>
                </div>
              </Grid>

            </Grid>


          </Grid>
          <Grid item md={3}>
            <div className="text-right" style={{ textAlign: "right" }}>
              <Button variant="contained" style={btnmr} onClick={this.props.closepopup} color="primary" id="Cancel" >Cancel</Button>
              <Button variant="contained" color="primary" id="Save" onClick={this.saveBtn}>Save</Button>
            </div>
          </Grid>

        </Grid>
      </>
    )
  }

}







export function LineColor(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const sendValue = (e) => {
    props.changecolor(e);
    handleClose();
  }

  return (
    <div>
      <IconButton style={{ color: props.linecolor }} onClick={handleClick} component="span">
        <FiberManualRecordIcon />
      </IconButton>

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={sendValue} value="#00aaf2"><span value="#00aaf2" style={{ width: "60px", height: "15px", backgroundColor: "#00aaf2" }}></span></MenuItem>
        <MenuItem onClick={sendValue} value="#20d426"><span value="#20d426" style={{ width: "60px", height: "15px", backgroundColor: "#20d426" }}></span></MenuItem>
        <MenuItem onClick={sendValue} value="#ff2f2b"><span value="#ff2f2b" style={{ width: "60px", height: "15px", backgroundColor: "#ff2f2b" }}></span></MenuItem>
        <MenuItem onClick={sendValue} value="#ffff2b"><span value="#ffff2b" style={{ width: "60px", height: "15px", backgroundColor: "#ffff2b" }}></span></MenuItem>
      </Menu>
    </div>
  );
}
export function LineWidth(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const sendValue = (e) => {
    props.changewidth(e);
    handleClose();
  }
  return (
    <div>
      <IconButton color="default" onClick={handleClick} component="span">
        <span style={{ width: "25px", height: props.lineHeight + "px", backgroundColor: props.linecolor, marginRight: "5px" }}></span>
      </IconButton>

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        onChange={(e) => console.log(e.currentTarget.value)}
      >
        <MenuItem onClick={sendValue} value="15"><span value="15" style={{ width: "40px", height: "15px", backgroundColor: props.linecolor, marginRight: "5px" }}></span> large</MenuItem>
        <MenuItem onClick={sendValue} value="10" ><span value="10" style={{ width: "40px", height: "10px", backgroundColor: props.linecolor, marginRight: "5px" }}></span> Medium</MenuItem>
        <MenuItem onClick={sendValue} value="5"><span value="5" style={{ width: "40px", height: "5px", backgroundColor: props.linecolor, marginRight: "5px" }}></span> Small</MenuItem>
        <MenuItem onClick={sendValue} value="1"><span value="1" style={{ width: "40px", height: "2px", backgroundColor: props.linecolor, marginRight: "5px" }}></span> Extra Small</MenuItem>
      </Menu>
    </div>
  );
}