import React from 'react'
import styled from 'styled-components'
import { colors } from '../utils/theme'

const StyledSpan = styled.span`
  color: ${colors.red};
`
const RequiredStar = () => <StyledSpan>*</StyledSpan>

export default RequiredStar
