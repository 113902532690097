import React from 'react'
import styled from 'styled-components'
import { colors, theme } from '../utils/theme'
import { Container } from '@material-ui/core'

const WhiteFrame = styled.div`
  padding: 24px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px 0px;
  background-color: ${colors.white};
  margin: 10px auto 40px;
  border-radius: 8px;

  ${theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`

export const Card = (props: any) => (
  <Container maxWidth="md">
    <WhiteFrame>{props.children}</WhiteFrame>
  </Container>
)
