import React from 'react';

const Layout =({children}) =>{
    return(
        <div>
         {children}
        </div>
    )
}

export default Layout;